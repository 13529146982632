var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-8 font-weight-regular text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b4.title"))+" ")]),(_vm.loadingData)?_c('v-container',{attrs:{"fluid":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#f25d3b"}})],1):_c('v-container',{attrs:{"fluid":""}},[_c('ESGTableRowEditor',{attrs:{"title":_vm.$t('pages.esg.report.b4.26.label'),"description":_vm.$t('pages.esg.report.b4.26.description'),"errorMessage":_vm.formErrors.pollutants,"errorsOnLines":_vm.formErrors.pollutantsLines,"columns":[
        {
          name: _vm.$t('pages.esg.report.b4.26.columns.pollutants_type'),
          key: 'type',
        },
        {
          name: _vm.$t('pages.esg.report.b4.26.columns.pollutants_amount'),
          key: 'amount',
          inputUnit: 'kg',
        },
        {
          name: _vm.$t('pages.esg.report.b4.26.columns.pollutants_where'),
          key: 'where',
        },
        {
          name: _vm.$t('pages.esg.report.b4.26.columns.pollutants_url'),
          key: 'url',
        },
      ]},model:{value:(_vm.pollutants),callback:function ($$v) {_vm.pollutants=$$v},expression:"pollutants"}}),_c('ESGConfirmButton',{attrs:{"loading":_vm.submittingData,"changesSaved":_vm.changesSaved},on:{"click":function($event){return _vm.save()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }