<template>
  <v-row>
    <v-col cols="12">
      <div v-if="title" class="grey--text body-2">
        {{ title }}
      </div>
      <div
        v-if="description || $scopedSlots['description']"
        class="black--text body-2"
      >
        <template v-if="$scopedSlots['description']">
          <slot name="description"></slot>
        </template>
        <template v-else>
          {{ description }}
        </template>
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left"
                v-for="(col, index) in columns"
                :key="index"
              >
                {{ col.name }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, rindex) in internalItems" :key="rindex">
              <template v-if="item.editable">
                <td v-for="(col, cindex) in columns" :key="cindex" class="px-1">
                  <v-tooltip bottom v-if="col.placeholder">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        v-model="item[col.key]"
                        hide-details
                        outlined
                        color="grey"
                        height="35px"
                        class="my-1 py-0 body-2 input"
                        :error="errorsOnLines?.includes(rindex)"
                        :placeholder="col.placeholder"
                      >
                        <template #append>
                          <div v-if="col.inputUnit">
                            {{ col.inputUnit }}
                          </div>
                        </template>
                      </v-text-field>
                    </template>
                    <span class="body-2">{{ col.placeholder }}</span>
                  </v-tooltip>
                  <v-text-field
                    v-else
                    v-model="item[col.key]"
                    hide-details
                    outlined
                    color="grey"
                    height="35px"
                    class="my-1 py-0 body-2 input"
                    :error="errorsOnLines?.includes(rindex)"
                    :placeholder="col.placeholder"
                  >
                    <template #append>
                      <div v-if="col.inputUnit">
                        {{ col.inputUnit }}
                      </div>
                    </template>
                  </v-text-field>
                </td>
                <td class="options-col">
                  <v-btn v-if="!staticRows" @click="deleteTableRow(rindex)" icon
                    ><v-icon>mdi-delete-outline</v-icon></v-btn
                  >
                  <v-btn @click="lockTableRow(rindex)" icon
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </td>
              </template>
              <template v-else>
                <td
                  v-for="(col, index) in columns"
                  :key="index"
                  :class="[
                    item[col.key] ? null : 'field-empty',
                    errorsOnLines?.includes(rindex) ? 'field-error' : null,
                  ]"
                >
                  {{
                    item[col.key]
                      ? item[col.key]
                      : $t("pages.esg.report.fieldEmpty")
                  }}
                  <template v-if="col.inputUnit && item[col.key]">{{
                    col.inputUnit
                  }}</template>
                </td>
                <td class="options-col">
                  <div class="d-flex justify-end">
                    <v-btn @click="editTableRow(rindex)" icon
                      ><v-icon size="23px">mdi-pencil</v-icon></v-btn
                    >
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-alert
        v-if="errorMessage"
        dense
        type="error"
        class="body-2 rounded-0 mt-4"
        outlined
      >
        {{ errorMessage }}
      </v-alert>
      <v-btn
        v-if="!staticRows"
        color="#f25d3b"
        text
        tile
        @click="addRowToTable()"
      >
        <v-icon left> mdi-plus </v-icon
        >{{ $t("pages.esg.report.b1.21c.addLine") }}
      </v-btn>
    </v-col></v-row
  >
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    value: Array,
    columns: Array,
    errorMessage: String,
    errorsOnLines: Array,
    staticRows: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    internalItems: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },
  },
  methods: {
    addRowToTable() {
      this.internalItems.push({
        editable: true,
      });
    },
    editTableRow(index) {
      this.internalItems[index].editable = true;
    },
    lockTableRow(index) {
      this.internalItems[index].editable = false;
    },
    deleteTableRow(index) {
      this.internalItems.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  :deep(.v-input__slot) {
    min-height: unset;
  }
  :deep(.v-input__append-inner) {
    margin-top: 10px;
  }
}

.options-col {
  width: 110px;
}

.field-empty {
  color: #b4b4b4;
}

.field-error {
  color: #ff5252;
}
</style>
