<template>
  <div class="d-flex flex justify-center align-center white">
    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <div
        class="d-flex justify-end mb-4"
        style="position: absolute; top: 30px; right: 50px"
      >
        <a
          href="https://verarca.com/kontakt/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-body-2 black--text"
          >{{ $t("pages.login.needHelp") }}</a
        >
      </div>
      <h1 class="text-h4 black--text mb-2 text-center">
        {{ $t("pages.register.title") }}
      </h1>
      <h1 class="text-subtitle-1 grey--text mb-6 font-weight-light text-center">
        {{ $t("pages.register.subTitle") }}
      </h1>
      <flash-messages />
      <error-messages :errors="form.errors" />
      <div class="d-flex justify-between">
        <div class="flex-fill mr-2">
          <input
            autofocus
            type="name"
            autocomplete="name"
            v-model="form.name"
            :placeholder="$t('pages.register.name')"
            name="name"
            class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
            style="width: 100%"
          />
        </div>
        <div class="flex-fill ml-2">
          <input
            autofocus
            type="surname"
            autocomplete="surname"
            v-model="form.surname"
            :placeholder="$t('pages.register.surname')"
            name="surname"
            class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
            style="width: 100%"
          />
        </div>
      </div>
      <input
        autofocus
        type="organization"
        autocomplete="organization"
        v-model="form.organization"
        :placeholder="$t('pages.register.organization')"
        name="organization"
        class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
        style="width: 100%"
      />

      <input
        autofocus
        type="email"
        autocomplete="email"
        v-model="form.email"
        :placeholder="$t('pages.login.email')"
        name="email"
        class="lightGrey black--text pl-4 pt-2 pb-2 mb-4 text-caption input"
        style="width: 100%"
      />

      <input
        autofocus
        type="password"
        autocomplete="password"
        v-model="form.password"
        :placeholder="$t('pages.login.password')"
        name="password"
        class="lightGrey black--text pl-5 pt-2 pb-2 mb-2 text-caption input"
        style="width: 100%"
      />

      <v-select
        class="rounded-0 black--text pt-2 mb-2 text-caption select-input"
        height="36px"
        solo
        flat
        background-color="#F7F7F7"
        :items="financialSystems"
        v-model="form.financialSystem"
        :placeholder="$t('pages.register.financialSystem')"
        dense
        filled
        tile
      >
      </v-select>

      <div class="d-flex align-center mb-6">
        <v-checkbox
          hide-details
          color="#F25D3B"
          class="pa-0 ma-0 textGrey--text"
          :label="$t('pages.register.termsOfService.understandAndAgree')"
          v-model="form.agreedToTermsOfService"
        ></v-checkbox>
        <a
          href="https://verarca.dk/abonnementsvilkaar/"
          target="_blank"
          rel="noopener noreferrer"
          class="black--text ml-2"
          >{{ $t("pages.register.termsOfService.terms") }}</a
        >
      </div>

      <v-btn
        tile
        block
        elevation="0"
        color="orange"
        class="font-weight-regular text-capitalize mb-2 white--text"
        type="submit"
        :disabled="form.processing"
      >
        {{ $t("pages.register.create") }}
      </v-btn>

      <i18n
        path="pages.register.haveAccount"
        tag="p"
        class="text-subtitle-1 grey--text mb-6 font-weight-light text-center"
      >
        <inertia-link
          :href="route('login')"
          class="text-subtitle-1 black--text mb-6 font-weight-light text-center"
        >
          {{ $t("pages.register.login") }}
        </inertia-link>
      </i18n>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import FlashMessages from "@/Shared/FlashMessages";
import ErrorMessages from "@/Shared/ErrorMessages";

export default {
  metaInfo: { title: "pages.register.title" },
  layout: Layout,
  components: {
    FlashMessages,
    ErrorMessages,
  },
  props: {
    errors: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        surname: null,
        organization: null,
        email: null,
        password: null,
        agreedToTermsOfService: false,
        financialSystem: null,
      }),
      financialSystems: [
        this.$t("pages.register.financialSystems.economic"),
        this.$t("pages.register.financialSystems.uniconta"),
        this.$t("pages.register.financialSystems.businessCentralCloud"),
        this.$t("pages.register.financialSystems.businessCentralServer"),
        this.$t("pages.register.financialSystems.nav"),
        this.$t("pages.register.financialSystems.other"),
      ],
    };
  },
  methods: {
    submit() {
      this.form.post(this.route("register.attempt"));
    },
  },
};
</script>
<style lang="scss" scoped>
input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}

.select-input {
  :deep(.v-input__control .v-input__slot) {
    padding-left: 20px !important;
  }

  :deep(.v-input__control
      .v-input__slot
      .v-select__slot
      .v-select__selections
      input) {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0333333333em !important;
    font-family: "Lexend", sans-serif !important;
    &::placeholder {
      color: rgb(102 99 99);
    }
  }
}
</style>
