<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b1.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGRadioButtons
        :title="$t('pages.esg.report.b1.21a.label')"
        :description="$t('pages.esg.report.b1.21a.description')"
        :options="b1_21aOptions"
        v-model="b1_21a"
        :errorMessage="formErrors.b1_21a"
      ></ESGRadioButtons>
      <ESGRadioButtons
        :title="$t('pages.esg.report.b1.21b.label')"
        :description="$t('pages.esg.report.b1.21b.description')"
        :options="b1_21bOptions"
        v-model="b1_21b"
        :errorMessage="formErrors.b1_21b"
      ></ESGRadioButtons>
      <ESGTableRowEditor
        v-if="b1_21b === consolidatedBasis"
        v-model="subsidiaries"
        :columns="[
          {
            name: $t('pages.esg.report.b1.21c.subsidiaryName'),
            key: 'name',
          },
          {
            name: $t('pages.esg.report.b1.21c.registeredAddress'),
            key: 'address',
          },
        ]"
        :errorMessage="formErrors.subsidiaries"
        :errorsOnLines="formErrors.subsidiariesLines"
      ></ESGTableRowEditor>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGRadioButtons from "../../../Components/ESGFormFields/ESGRadioButtons.vue";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";
import { serialize } from "object-to-formdata";
import {
  b1_21a_fieldValues,
  b1_21b_fieldValues,
} from "@/util/esgReportFieldValues";

export default {
  components: { ESGTableRowEditor, ESGRadioButtons, ESGConfirmButton },
  props: {
    reportId: String,
  },
  data() {
    return {
      b1_21a: "",
      b1_21b: "",
      subsidiaries: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b1",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b1_21a = data.question21aSelectOptionId ?? this.b1_21a;
          this.b1_21b = data.question21bSelectOptionId ?? this.b1_21b;
          if (
            data.question21cRows !== null &&
            data.question21cRows.length > 0
          ) {
            this.subsidiaries = data.question21cRows.map((sub) => {
              return { editable: false, ...sub };
            });
          }
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateReportData({
        b1_21a: this.b1_21a,
        b1_21b: this.b1_21b,
        subsidiaries: this.subsidiaries,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      const formData = {
        question21aSelectOptionId: this.b1_21a,
        question21bSelectOptionId: this.b1_21b,
        question21cRows: this.subsidiaries,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b1", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateReportData(reportData) {
      const errors = {};

      if (reportData.b1_21a === "") {
        errors.b1_21a = this.$t(
          "pages.esg.report.validation.pleaseSelectValue"
        );
      }

      if (reportData.b1_21b === "") {
        errors.b1_21b = this.$t(
          "pages.esg.report.validation.pleaseSelectValue"
        );
      }

      // Subsidiaries
      const unconfirmedChanges =
        reportData.subsidiaries
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter((sub) => sub.editable && (sub.name || sub.address)) ?? [];

      if (unconfirmedChanges.length > 0) {
        errors.subsidiaries = this.$t(
          "pages.esg.report.validation.youHaveUnconfirmedChanges"
        );
        errors.subsidiariesLines = unconfirmedChanges.map((sub) => sub.index);
      }

      return errors;
    },
  },
  computed: {
    consolidatedBasis(){
      return b1_21b_fieldValues.consolidatedBasis;
    },
    b1_21aOptions() {
      return [
        {
          name: this.$t("pages.esg.report.b1.21a.option1.name"),
          description: this.$t("pages.esg.report.b1.21a.option1.desc"),
          key: b1_21a_fieldValues.basicModeOnly,
        },
        {
          name: this.$t("pages.esg.report.b1.21a.option2.name"),
          description: this.$t("pages.esg.report.b1.21a.option2.desc"),
          key: b1_21a_fieldValues.basicAndNarrativePAT,
        },
        {
          name: this.$t("pages.esg.report.b1.21a.option3.name"),
          description: this.$t("pages.esg.report.b1.21a.option3.desc"),
          key: b1_21a_fieldValues.basicAndBusinessPartners,
        },
        {
          name: this.$t("pages.esg.report.b1.21a.option4.name"),
          description: this.$t("pages.esg.report.b1.21a.option4.desc"),
          key: b1_21a_fieldValues.basicAndNarrativePATAndBusinessPartner,
        },
      ];
    },
    b1_21bOptions() {
      return [
        {
          name: this.$t("pages.esg.report.b1.21b.individual.name"),
          description: this.$t("pages.esg.report.b1.21b.individual.desc"),
          key: b1_21b_fieldValues.individualBasis,
        },
        {
          name: this.$t("pages.esg.report.b1.21b.consolidated.name"),
          description: this.$t("pages.esg.report.b1.21b.consolidated.desc"),
          key: b1_21b_fieldValues.consolidatedBasis,
        },
      ];
    },
  },
  watch: {
    subsidiaries: {
      handler() {
        this.formErrors.subsidiaries = null;
        this.formErrors.subsidiariesLines = [];
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
      deep: true,
    },
    b1_21a() {
      this.formErrors.b1_21a = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b1_21b() {
      this.formErrors.b1_21b = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
