<template>
  <v-row class="px-2">
    <template v-if="context === 'dashboard'">
      <v-col cols="12" sm="6" md="3" class="px-2 py-4">
        <DynamicFocusField
          class="mb-4"
          :assetCardData="assetCardData"
          :selectedDynamicFocusFieldKey="dynamicFocusFields[0]"
          @changed-dynamic-focus="(key) => setDynamicFocusModeForCard(0, key)" 
        ></DynamicFocusField>
        <DynamicFocusField
          :assetCardData="assetCardData"
          :selectedDynamicFocusFieldKey="dynamicFocusFields[1]"
          @changed-dynamic-focus="(key) => setDynamicFocusModeForCard(1, key)" 
        ></DynamicFocusField>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="px-2 py-4">
        <DynamicFocusField
          class="mb-4"
          :assetCardData="assetCardData"
          :selectedDynamicFocusFieldKey="dynamicFocusFields[2]"
          @changed-dynamic-focus="(key) => setDynamicFocusModeForCard(2, key)" 
        ></DynamicFocusField>
        <DynamicFocusField
          :assetCardData="assetCardData"
          :selectedDynamicFocusFieldKey="dynamicFocusFields[3]"
          @changed-dynamic-focus="(key) => setDynamicFocusModeForCard(3, key)" 
        ></DynamicFocusField>
      </v-col>
      <slot name="row-end"></slot>
    </template>
    <v-col
      v-else
      v-for="(card, index) in dynamicFocusFields"
      :key="index"
      cols="12"
      md="6"
      lg="3"
      class="px-2 py-2 pt-4 d-flex"
    >
      <DynamicFocusField
        :assetCardData="assetCardData"
        :selectedDynamicFocusFieldKey="dynamicFocusFields[index]"
        @changed-dynamic-focus="(key) => setDynamicFocusModeForCard(index, key)"
      ></DynamicFocusField>
    </v-col>
  </v-row>
</template>

<script>
import {
  integerNumberFormatter,
  currencyFormatter,
} from "../../util/formatters";

import Formatters from "../../mixins/Formatters.vue";
import DynamicFocusField from "../Outputs/DynamicFocusField.vue";
import Vue from "vue";

export default {
  components: {
    DynamicFocusField,
  },
  mixins: [Formatters],
  props: {
    assetCardData: Object,
    context: String, 
  },
  data() {
    return {
      dynamicFocusFields: [
        "total_co2_emissions",
        "entries",
        "files",
        "total_purchase_amount",
      ],
    };
  },
  mounted() {
    const dynamicFocusFields = localStorage.getItem(
      "dynamic-focus-fields_" + this.context
    );
    if (dynamicFocusFields !== null) {
      this.dynamicFocusFields = JSON.parse(dynamicFocusFields);
    }
  },
  methods: {
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
    setDynamicFocusModeForCard(cardIndex, dynamicFocusKey) {
      // Set state
      //this.dynamicFocusFields[cardIndex] = dynamicFocusKey;
      const indexOfExistingKey = this.dynamicFocusFields.findIndex(
        (key) => key === dynamicFocusKey
      );

      // If exists swap values
      if (indexOfExistingKey !== -1) {
        Vue.set(
          this.dynamicFocusFields,
          indexOfExistingKey,
          this.dynamicFocusFields[cardIndex]
        );
      }

      Vue.set(this.dynamicFocusFields, cardIndex, dynamicFocusKey);

      // Set storage
      localStorage.setItem(
        "dynamic-focus-fields_" + this.context,
        JSON.stringify(this.dynamicFocusFields)
      );
    },
  },
  computed: {
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData?.scope1Emissions;
        const scope2 = this.assetCardData?.scope2Emissions;
        const scope3 = this.assetCardData?.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#6493BE",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#E35E3E",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    totalSpentAmount() {
      // get amount from assetCardData
      let total = this.assetCardData?.totalAmountSpent?.value ?? 0;

      return currencyFormatter.format(total);
    },
    totalSpentAmountUnit() {
      return this.assetCardData?.totalAmountSpent?.unit;
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "0";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;
      return this.formatEmissions(totalCo2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
