<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b7.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText>
        <i18n path="pages.esg.report.b7.32.questionHeader" tag="span">
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b7.circularEconomyPrinciples")
          }}</span></i18n
        ></ESGText
      >
      <ESGText>{{ $t("pages.esg.report.b7.32.questionText") }}</ESGText>
      <ESGTextArea
        v-model="description"
        :counter="descriptionCharLimit"
        :errorMessage="formErrors.description"
        :fieldLabel="$t('pages.esg.report.b7.32.fieldLabel')"
      ></ESGTextArea>
      <ESGRadioButtons
        :title="
          $t(
            'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciples.questionText'
          )
        "
        :options="doesApplyCircularEconomyPrinciplesOptions"
        v-model="doesApplyCircularEconomyPrinciples"
        :errorMessage="formErrors.doesApplyCircularEconomyPrinciples"
      ></ESGRadioButtons>
      <template
        v-if="
          doesApplyCircularEconomyPrinciples ===
          doesApplyCircularEconomyPrinciplesOptionValues.yes
        "
      >
        <ESGText>{{
          $t(
            "pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.questionHeader"
          )
        }}</ESGText>
        <ESGTextArea
          :fieldLabel="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question1.text'
            )
          "
          :counter="1000"
          :rows="3"
          :placeholder="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question1.placeholder'
            )
          "
          v-model="minimizingWasteAndPollution"
        ></ESGTextArea>
        <ESGTextArea
          :fieldLabel="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question2.text'
            )
          "
          :counter="1000"
          :rows="3"
          :placeholder="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question2.placeholder'
            )
          "
          v-model="circulationOfProductsAndMaterials"
        ></ESGTextArea>
        <ESGTextArea
          :fieldLabel="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question3.text'
            )
          "
          :counter="1000"
          :rows="3"
          :placeholder="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question3.placeholder'
            )
          "
          v-model="regenerationOfNatureInAreas"
        ></ESGTextArea>
        <!-- Dynamic fields -->

        <ESGTextArea
          v-for="(additinalSpecificEffort, index) in additinalSpecificEfforts"
          :key="index"
          :fieldLabel="additinalSpecificEffort.fieldLabel"
          v-model="additinalSpecificEffort.value"
          :counter="1000"
          :rows="3"
          :placeholder="
            $t(
              'pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.question3.placeholder'
            )
          "
          deletable
          @delete="removeAdditionalSpecificEffort(index)"
        ></ESGTextArea>
        <v-btn color="#f25d3b" @click="addAdditinalSpecificEfforts()" text tile>
          <v-icon left> mdi-plus </v-icon
          >{{
            $t(
              "pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.optional.addOptionalSpecificEffort"
            )
          }}
        </v-btn>
      </template>
      <ESGText class="grey--text body-2 mb-0">{{
        $t("pages.esg.report.b7.33.questionNumber")
      }}</ESGText>
      <ESGText>{{ $t("pages.esg.report.b7.33.questionHeader") }}</ESGText>
      <ESGTableRowEditor
        :title="$t('pages.esg.report.b7.33.a-b.questionNumber')"
        :description="$t('pages.esg.report.b7.33.a-b.questionHeader')"
        v-model="b7_33_ab"
        :errorMessage="formErrors.b7_33_ab"
        :errorsOnLines="formErrors.b7_33_ab"
        :columns="[
          {
            name: $t('pages.esg.report.b7.33.a-b.columns.name'),
            key: 'name',
          },
          {
            name: $t('pages.esg.report.b7.33.a-b.columns.type'),
            key: 'type',
          },
          {
            name: $t('pages.esg.report.b7.33.a-b.columns.recycled_content'),
            key: 'recycledContent',
          },
          {
            name: $t('pages.esg.report.b7.33.a-b.columns.recyclable_content'),
            key: 'recyclableContent',
          },
        ]"
      ></ESGTableRowEditor>
      <ESGText class="grey--text body-2 mb-0">{{
        $t("pages.esg.report.b7.33.c.questionNumber")
      }}</ESGText>
      <ESGText>{{ $t("pages.esg.report.b7.33.c.questionHeader") }}</ESGText>
      <ESGTextField
        :questionText="$t('pages.esg.report.b7.33.c.1.questionText')"
        :inputUnit="$t('pages.esg.report.b7.33.c.1.inputUnit')"
        v-model="b7_33c_1"
        :errorMessage="formErrors.b7_33c_1"
      >
      </ESGTextField>
      <ESGTextField
        :inputUnit="$t('pages.esg.report.b7.33.c.2.inputUnit')"
        v-model="b7_33c_2"
        :errorMessage="formErrors.b7_33c_2"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b7.33.c.2.questionText" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b7.33.c.2.hazardous")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b7.33.c.3.questionText')"
        :inputUnit="$t('pages.esg.report.b7.33.c.3.inputUnit')"
        v-model="b7_33c_3"
        :errorMessage="formErrors.b7_33c_3"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b7.33.c.3.questionText" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b7.33.c.3.non-hazardous")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <ESGText class="grey--text body-2 mb-0">{{
        $t("pages.esg.report.b7.33.d.questionNumber")
      }}</ESGText>
      <ESGText>{{ $t("pages.esg.report.b7.33.d.questionHeader") }}</ESGText>

      <ESGTextField
        :inputUnit="$t('pages.esg.report.b7.33.d.inputUnit')"
        v-model="b7_33d"
        :errorMessage="formErrors.b7_33d"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b7.33.d.questionText" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b7.33.d.recycling")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import { serialize } from "object-to-formdata";
import ESGTextArea from "../../../Components/ESGFormFields/ESGTextArea.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import ESGRadioButtons from "../../../Components/ESGFormFields/ESGRadioButtons.vue";
import { doesApplyCircularEconomyPrinciplesOptionValues } from "@/util/esgReportFieldValues";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";

export default {
  components: {
    ESGConfirmButton,
    ESGTextArea,
    ESGText,
    ESGRadioButtons,
    ESGTableRowEditor,
    ESGTextField,
  },
  props: {
    reportId: String,
  },
  data() {
    return {
      description: "",
      formErrors: {},
      descriptionCharLimit: 2500,
      loadingData: false,
      submittingData: false,
      changesSaved: false,
      doesApplyCircularEconomyPrinciples: "",
      additinalSpecificEfforts: [],
      circulationOfProductsAndMaterials: "",
      minimizingWasteAndPollution: "",
      regenerationOfNatureInAreas: "",
      b7_33_ab: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      b7_33c_1: "",
      b7_33c_2: "",
      b7_33c_3: "",
      b7_33d: "",
    };
  },
  mounted() {
    this.getFormData();
  },
  computed: {
    doesApplyCircularEconomyPrinciplesOptions() {
      return [
        {
          name: this.$t(
            "pages.esg.report.b7.32.doesApplyCircularEconomyPrinciples.yes"
          ),
          key: doesApplyCircularEconomyPrinciplesOptionValues.yes,
        },
        {
          name: this.$t(
            "pages.esg.report.b7.32.doesApplyCircularEconomyPrinciples.no"
          ),
          key: doesApplyCircularEconomyPrinciplesOptionValues.no,
        },
      ];
    },
    doesApplyCircularEconomyPrinciplesOptionValues() {
      return doesApplyCircularEconomyPrinciplesOptionValues;
    },
  },
  methods: {
    addAdditinalSpecificEfforts(value = "") {
      const additinalSpecificEfforts = {
        fieldLabel: this.$t(
          "pages.esg.report.b7.32.doesApplyCircularEconomyPrinciplesForms.yes.inputs.optional.text"
        ),
        value: value,
      };
      this.additinalSpecificEfforts.push(additinalSpecificEfforts);
    },
    removeAdditionalSpecificEffort(index) {
      this.additinalSpecificEfforts = this.additinalSpecificEfforts.filter(
        (_, i) => i !== index
      );
    },
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b7",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.description = data.questionDescription;
          this.doesApplyCircularEconomyPrinciples =
            data.questionDoesApplyCircularEconomyPrinciples;

          this.circulationOfProductsAndMaterials =
            data.questionCirculationOfProductsAndMaterials;
          this.minimizingWasteAndPollution =
            data.questionMinimizingWasteAndPollution;
          this.regenerationOfNatureInAreas =
            data.questionRegenerationOfNatureInAreas;

          if (data.questionAdditionalEfforts) {
            data.questionAdditionalEfforts.forEach((effort) => {
              this.addAdditinalSpecificEfforts(effort);
            });
          }

          if (data.question33a33b !== null && data.question33a33b.length > 0) {
            this.b7_33_ab = data.question33a33b.map((sub) => {
              return { editable: false, ...sub };
            });
          }

          this.b7_33c_1 = data.question33cTotalAnnualGenerationOfWaste;
          this.b7_33c_2 = data.question33cTotalAnnualGenerationOfHazardousWaste;
          this.b7_33c_3 =
            data.question33cTotalAnnualGenerationOfNonHazardousWaste;

          this.b7_33d =
            data.question33dTotalAnnualWasteDivertedToRecyclingOrReuse;
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        description: this.description,
        doesApplyCircularEconomyPrinciples:
          this.doesApplyCircularEconomyPrinciples,
        circulationOfProductsAndMaterials:
          this.circulationOfProductsAndMaterials,
        minimizingWasteAndPollution: this.minimizingWasteAndPollution,
        regenerationOfNatureInAreas: this.regenerationOfNatureInAreas,
        additinalSpecificEfforts: this.additinalSpecificEfforts,
        b7_33_ab: this.b7_33_ab,
        b7_33c_1: this.b7_33c_1,
        b7_33c_2: this.b7_33c_2,
        b7_33c_3: this.b7_33c_3,
        b7_33d: this.b7_33d,
      });

      if (Object.keys(errors).length > 0) {
        this.submittingData = false;
        this.formErrors = errors;
        return;
      }

      const formData = {
        questionDescription: this.description,
        questionDoesApplyCircularEconomyPrinciples:
          this.doesApplyCircularEconomyPrinciples,
        questionCirculationOfProductsAndMaterials:
          this.circulationOfProductsAndMaterials,
        questionMinimizingWasteAndPollution: this.minimizingWasteAndPollution,
        questionRegenerationOfNatureInAreas: this.regenerationOfNatureInAreas,
        questionAdditionalEfforts: this.additinalSpecificEfforts.map(
          (a) => a.value
        ),
        question33a33b: this.b7_33_ab,

        question33cTotalAnnualGenerationOfWaste: this.b7_33c_1,
        question33cTotalAnnualGenerationOfHazardousWaste: this.b7_33c_2,
        question33cTotalAnnualGenerationOfNonHazardousWaste: this.b7_33c_3,
        question33dTotalAnnualWasteDivertedToRecyclingOrReuse: this.b7_33d,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b7", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateFormData(formData) {
      const errors = {};

      if (formData.description.length === 0) {
        errors.description = this.$t(
          "pages.esg.report.validation.pleaseFillField"
        );
      } else if (formData.description.length > this.descriptionCharLimit) {
        errors.description = this.$t(
          "pages.esg.report.validation.tooManyCharacters"
        );
      }

      if (formData.doesApplyCircularEconomyPrinciples === "") {
        errors.doesApplyCircularEconomyPrinciples = this.$t(
          "pages.esg.report.validation.pleaseSelectValue"
        );
      }

      return errors;
    },
  },
  watch: {
    description: {
      deep: true,
      handler(newValue) {
        if (newValue.length < this.descriptionCharLimit) {
          this.formErrors.description = null;
          this.changesSaved = false;
        }
        this.$emit("form-state-changed", true);
      },
    },
    doesApplyCircularEconomyPrinciples: {
      deep: true,
      handler() {
        this.formErrors.doesApplyCircularEconomyPrinciples = null;
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    additinalSpecificEfforts: {
      deep: true,
      handler() {
        this.formErrors.additinalSpecificEfforts = null;
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    }, 
    circulationOfProductsAndMaterials() {
      this.formErrors.circulationOfProductsAndMaterials = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    minimizingWasteAndPollution() {
      this.formErrors.minimizingWasteAndPollution = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    regenerationOfNatureInAreas() {
      this.formErrors.regenerationOfNatureInAreas = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b7_33_ab: {
      deep: true,
      handler() {
        this.formErrors.b7_33_ab = null;
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b7_33c_1() {
      this.formErrors.b7_33c_1 = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b7_33c_2() {
      this.formErrors.b7_33c_2 = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b7_33c_3() {
      this.formErrors.b7_33c_3 = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b7_33d() {
      this.formErrors.b7_33d = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
