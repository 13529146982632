<template>
  <div class="">
    <v-row align="center">
      <div class="col-12 border mb-3" style="height: 90px">
        <CategoryAccountMapper
          v-show="!loadingAccountMapper"
          :categories="selectableCategories"
          @updateCategories="(val) => (selectableCategories = val)"
          :accounts="accounts"
          :categoryMode="categoryMode"
          :syncAccounts="
            categoryMode === 1 ? accountsToSync : standardAccountsToSync
          "
          @toggleSync="({ id, value }) => setAccountSync(id, value)"
          :autoMappedAccounts="categoryMode === 1 ? autoMappedAccounts : {}"
          :readOnly="mappingIsReadOnly"
          @changedCategoryMode="(val) => (categoryMode = val)"
        ></CategoryAccountMapper>
        <div
          v-if="loadingAccountMapper"
          class="flex-grow-1 d-flex align-center justify-center"
          style="height: 50px"
        >
          <activity-indicator></activity-indicator>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import CategoryAccountMapper from "../../Inputs/CategoryAccountMapperNew.vue";
import Vue from "vue";
import ActivityIndicator from "../../Outputs/ActivityIndicator.vue";

export default {
  components: { CategoryAccountMapper, ActivityIndicator },
  props: {
    integrationId: String,
    integrationInstalled: Boolean,
    mappingIsReadOnly: Boolean,
  },
  data() {
    return {
      categoryMap: [],
      standardAccountsMap: [],
      accounts: [],
      accountsToSync: {},
      autoMappedAccounts: {},
      loadingAccountMapper: false,
      suggestedGhgCategoryIds: {},
      categoryMode: 1,
      standardAccountsToSync: {},
    };
  },
  watch: {
    mappingIsReadOnly(to) {
      if (to) {
        this.categoryMap = [];
        this.standardAccountsMap = [];
        this.accounts = [];
        this.accountsToSync = {};
        this.autoMappedAccounts = {};
        this.syncAccounts();
      }
    },
    categoryMode(to) {
      if (to === 0) {
        this.standardAccountsToSync = {};
      }
    },
  },
  mounted() {
    this.syncAccounts();

    // When setting up integration get integration id from financial system
    // When editing existing integration get integration id from integration
  },
  methods: {
    setAccountSync(id, value) {
      if (this.mappingIsReadOnly || this.categoryMode === 1) {
        Vue.set(this.accountsToSync, id, value);
      } else {
        Vue.set(this.standardAccountsToSync, id, value);
      }
    },
    // Syncs accounts from integration
    syncAccounts() {
      this.loadingAccountMapper = true;
      fetch(
        this.route("api.onboarding.integrations.accounts.sync", {
          integrationid: this.integrationId,
        }),
        {
          method: "POST",
        }
      )
        .then(() => {
          this.getAccountsAndCategories();
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    // Get categories and accounts from server
    async getAccountsAndCategories() {
      fetch(
        this.route("onboarding.accountNumberMapper", {
          integrationId: this.integrationId,
          setupDefaults: this.integrationInstalled ? "true" : "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.accounts = [...this.formatAccounts(data.economicAccounts)];

          this.categoryMap = [...this.formatCategories(data)];
          this.standardAccountsMap = [
            ...this.formatStandardAccounts(data.standardAccounts),
          ];

          this.getSelectedCategoryOptions();

          this.getSelectedStandardAccountsOptions(data.standardAccounts);
        })
        .catch(() => {
          this.loadingAccountMapper = false;
        });
    },
    formatStandardAccounts(standardAccounts) {
      return standardAccounts.map((account) => {
        return {
          id: account.ghgCategory.id,
          standardAccountId: account.id,
          scope: account.ghgCategory.scope,
          scopeId: account.ghgCategory.scopeId,
          name: account.accountName,
          accountNumbers: [],
          headline: account.headline,
          headlineId: account.headlineId,
        };
      });
    },
    // Formatter for categories and accounts
    formatCategories(data) {
      let _categoryMap = [];

      data.scopes.forEach((scope) => {
        const catsInScope = data.categories.filter(
          (category) => category.scopeId === scope.id
        );
        catsInScope.forEach((category) => {
          _categoryMap.push({
            ...category,
            accountNumbers: [],
          });
        });
      });

      return _categoryMap.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    formatAccounts(data) {
      const _accounts = [];
      const currentParent = {};
      const catchAllParent = {
        name: this.$t("components.integrations.chooseAccounts.otherAccounts"),
        accountNumber: -1,
        accountNumbers: [],
      };

      data.forEach((account) => {
        if (
          account.accountType === "heading" ||
          account.accountType === "Heading" ||
          account.accountType === "Header" ||
          account.accountType.includes("Begin")
        ) {
          // Add last parentobject when encountering a new heading (Ignore first time since no parent object data has been added yet)
          if (currentParent.name && currentParent.accountNumbers.length > 0) {
            _accounts.push({ ...currentParent });
          }
          currentParent.name = account.name;
          currentParent.accountNumber = account.accountNumber;
          currentParent.accountNumbers = [];
        } else {
          const accountNumber = {
            name: account.name,
            number: account.accountNumber,
            id: account.id,
            balance: account.balance,
          };

          if (currentParent.accountNumbers) {
            currentParent.accountNumbers.push(accountNumber);
          } else {
            catchAllParent.accountNumbers.push(accountNumber);
          }
        }
      });

      if (currentParent.accountNumbers?.length > 0) {
        _accounts.push(currentParent);
      }

      if (catchAllParent.accountNumbers.length > 0) {
        _accounts.push(catchAllParent);
      }

      return _accounts;
    },

    // Adds selected options to categorymap
    getSelectedCategoryOptions() {
      fetch(
        this.route("financial-account-mappings.list", {
          integrationid: this.integrationId,
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Loop though all relations
          data.forEach((attributeAccountLink) => {
            const account = attributeAccountLink.financialAccount;

            const suggestedGhgCategoryId =
              attributeAccountLink.suggestedGhgCategoryId;

            // Set account sync to true if present
            Vue.set(this.accountsToSync, account.id, false);

            // add to list of automapped
            Vue.set(this.autoMappedAccounts, account.id, false);

            // add to list of automapped
            Vue.set(
              this.suggestedGhgCategoryIds,
              account.id,
              suggestedGhgCategoryId
            );

            // loop though all category
            this.categoryMap.forEach((category, index) => {
              // If category id matches
              if (category.id === attributeAccountLink.ghgCategoryId) {
                // add account number to category
                this.categoryMap[index].accountNumbers.push({
                  name: account.name,
                  number: account.accountNumber,
                  id: account.id,
                });

                Vue.set(this.accountsToSync, account.id, true);
              }
            });

            // If account balance is 0 untoggle by default
            if (
              this.accountsFlattened.find((acc) => acc.id === account.id)
                .balance == 0
            ) {
              Vue.set(this.accountsToSync, account.id, false);
            }
          });
        })
        .finally(() => {
          this.loadingAccountMapper = false;
        });
    },
    getSelectedStandardAccountsOptions() {
      fetch(
        this.route("financial-account-mappings.list", {
          integrationid: this.integrationId,
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // Loop though all relations
          data.forEach((attributeAccountLink) => {
            const account = attributeAccountLink.financialAccount;
            const autoMappingSuggestion =
              attributeAccountLink.autoMappingSuggestion;
            const suggestedGhgCategoryId =
              attributeAccountLink.suggestedGhgCategoryId;

            // Set account sync to true if present
            Vue.set(this.standardAccountsToSync, account.id, true);

            // add to list of automapped
            Vue.set(this.autoMappedAccounts, account.id, autoMappingSuggestion);

            // add to list of automapped
            Vue.set(
              this.suggestedGhgCategoryIds,
              account.id,
              suggestedGhgCategoryId
            );

            // loop though all category groups
            this.standardAccountsMap.forEach((category, index) => {
              // If category id matches
              if (
                category.standardAccountId ===
                attributeAccountLink?.standardFinancialAccountId
              ) {
                // add account number to category
                this.standardAccountsMap[index].accountNumbers.push({
                  name: account.name,
                  number: account.accountNumber,
                  id: account.id,
                });
              }
            });
          });
        })
        .finally(() => {
          this.loadingAccountMapper = false;
        });
    },

    // Sets up links for account and categories
    setLinkedAccounts(callback) {
      // Add updated
      const links = [];

      const accToSync =
        this.categoryMode === 1
          ? this.accountsToSync
          : this.standardAccountsToSync;

      this.selectableCategories.forEach((category) => {
        category.accountNumbers.forEach((x) => {
          if (accToSync[x.id]) {
            links.push({
              ghgCategoryId: category.id,
              standardFinancialAccountId: category.standardAccountId,
              financialAccountId: x.id,
              autoMappingSuggestion: this.autoMappedAccounts[x.id],
              suggestedGhgCategoryId: this.suggestedGhgCategoryIds[x.id],
            });
          }
        });
      });

      // Find accounts that have sync enabled but are not included in a category
      const accountsToSyncWithoutCategory = Object.keys(accToSync).filter(
        (key) =>
          accToSync[key] === true &&
          links.filter((link) => link.financialAccountId === key).length === 0
      );

      // Add to links
      accountsToSyncWithoutCategory.forEach((accountId) =>
        links.push({
          ghgCategoryId: null,
          standardFinancialAccountId: null,
          financialAccountId: accountId,
          autoMappingSuggestion: this.autoMappedAccounts[accountId],
          suggestedGhgCategoryId: this.suggestedGhgCategoryIds[accountId],
        })
      );

      const payload = {
        links: links,
        integrationId: this.integrationId,
        usingStandardAccounts: this.categoryMode ? false : true,
      };

      fetch(this.route("financial-account-mappings.update"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then(() => {
          callback();
        });
    },
  },
  computed: {
    accountsFlattened() {
      var _accounts = [];
      this.accounts.forEach((accG) => {
        accG.accountNumbers.forEach((acc) => {
          _accounts.push(acc);
        });
      });
      return _accounts;
    },
    selectableCategories: {
      get() {
        if (this.mappingIsReadOnly || this.categoryMode === 1) {
          return this.categoryMap;
        }
        return this.standardAccountsMap;
      },
      set(val) {
        if (this.mappingIsReadOnly || this.categoryMode === 1) {
          this.categoryMap = val;
          return;
        }

        this.standardAccountsMap = val;
      },
    },
  },
};
</script>
