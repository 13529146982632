<template>
  <v-row>
    <v-col>
      <div class="body-2" :class="textClass">
        <slot></slot>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
    props: {
        textClass: String,
    }
};
</script>

<style lang="scss" scoped></style>
