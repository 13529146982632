<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b6.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText textClass="grey--text">{{
        $t("pages.esg.report.b6.30.label")
      }}</ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b6.30.description" tag="span">
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b6.waterWithdrawal")
          }}</span></i18n
        ></ESGText
      >
      <ESGTextField
        :questionText="$t('pages.esg.report.b6.30.totalWaterWithdrawal.label')"
        :inputUnit="$t('pages.esg.report.b6.30.totalWaterWithdrawal.inputUnit')"
        v-model="b6_30_total_withdrawal"
        :errorMessage="formErrors.b6_30_total_withdrawal"
      ></ESGTextField>
      <ESGTextField
        class="mb-16"
        :questionText="
          $t('pages.esg.report.b6.30.waterWithdrawnAtSitesStress.label')
        "
        :inputUnit="
          $t('pages.esg.report.b6.30.waterWithdrawnAtSitesStress.inputUnit')
        "
        v-model="b6_30_withdrawn_at_sites"
        :errorMessage="formErrors.b6_30_withdrawn_at_sites"
      ></ESGTextField>

      <ESGText textClass="grey--text">{{
        $t("pages.esg.report.b6.31.label")
      }}</ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b6.31.description" tag="span">
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b6.waterConsumption")
          }}</span
          ><span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b6.waterWithdrawal")
          }}</span></i18n
        ></ESGText
      >
      <ESGTextField
        :questionText="$t('pages.esg.report.b6.31.totalWaterWithdrawal.label')"
        :inputUnit="$t('pages.esg.report.b6.31.totalWaterWithdrawal.inputUnit')"
        v-model="b6_31_total_withdrawal"
        :errorMessage="formErrors.b6_31_total_withdrawal"
      ></ESGTextField>
      <ESGTextField
        class="mb-16"
        :questionText="
          $t('pages.esg.report.b6.31.waterWithdrawnAtSitesStress.label')
        "
        :inputUnit="
          $t('pages.esg.report.b6.31.waterWithdrawnAtSitesStress.inputUnit')
        "
        v-model="b6_31_withdrawn_at_sites"
        :errorMessage="formErrors.b6_31_withdrawn_at_sites"
      ></ESGTextField>

      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import { serialize } from "object-to-formdata";

export default {
  components: { ESGTextField, ESGConfirmButton, ESGText },
  props: {
    reportId: String,
  },
  data() {
    return {
      b6_30_total_withdrawal: "",
      b6_30_withdrawn_at_sites: "",
      b6_31_total_withdrawal: "",
      b6_31_withdrawn_at_sites: "",
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b6",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b6_30_total_withdrawal = data.question30TotalWithdrawal;
          this.b6_30_withdrawn_at_sites = data.question30WithdrawnAtSites;
          this.b6_31_total_withdrawal = data.question31TotalWithdrawal;
          this.b6_31_withdrawn_at_sites = data.question31WithdrawnAtSites;
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b6_30_total_withdrawal: this.b6_30_total_withdrawal,
        b6_30_withdrawn_at_sites: this.b6_30_withdrawn_at_sites,
        b6_31_total_withdrawal: this.b6_31_total_withdrawal,
        b6_31_withdrawn_at_sites: this.b6_31_withdrawn_at_sites,
      });

      if (Object.keys(errors).length > 0) {
        this.submittingData = false;
        this.formErrors = errors;
        return;
      }

      const formData = {
        question30TotalWithdrawal: this.b6_30_total_withdrawal,
        question30WithdrawnAtSites: this.b6_30_withdrawn_at_sites,
        question31TotalWithdrawal: this.b6_31_total_withdrawal,
        question31WithdrawnAtSites: this.b6_31_withdrawn_at_sites,
      };
 
      // Send data
      fetch(this.route("api.esg-reports.sections.b6", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateFormData(formData) {
      const errors = {};

      Object.keys(formData).forEach((key) => {
        if (isNaN(formData[key])) {
          errors[key] = this.$t(
            "pages.esg.report.validation.valueMustBeNumber"
          );
        }
      });

      return errors;
    },
  },
  watch: {
    b6_30_total_withdrawal() {
      this.formErrors.b6_30_total_withdrawal = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b6_30_withdrawn_at_sites() {
      this.formErrors.b6_30_withdrawn_at_sites = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b6_31_total_withdrawal() {
      this.formErrors.b6_31_total_withdrawal = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b6_31_withdrawn_at_sites() {
      this.formErrors.b6_31_withdrawn_at_sites = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
