<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b12.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b12.38.questionNumber") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b12.38.questionHeader") }}
      </ESGText>

      <ESGTextField
        :questionText="
          $t('pages.esg.report.b12.38.numberOfConvictions.questionText')
        "
        :placeholder="
          $t('pages.esg.report.b12.38.numberOfConvictions.placeholder')
        "
        v-model="b12_38_numberOfConvictions"
        :errorMessage="formErrors.b12_38_numberOfConvictions"
      ></ESGTextField>
      <ESGTextField
        :questionText="
          $t('pages.esg.report.b12.38.totalNumberOfFines.questionText')
        "
        :placeholder="
          $t('pages.esg.report.b12.38.totalNumberOfFines.placeholder')
        "
        v-model="b12_38_totalNumberOfFines"
        :errorMessage="formErrors.b12_38_totalNumberOfFines"
      ></ESGTextField>

      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import { serialize } from "object-to-formdata";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";

export default {
  components: {
    ESGText,
    ESGConfirmButton,
    ESGTextField,
  },
  props: {
    reportId: String,
  },
  data() {
    return {
      b12_38_numberOfConvictions: "",
      b12_38_totalNumberOfFines: "",
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b12",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b12_38_numberOfConvictions = data.question38NumberOfConvictions;
          this.b12_38_totalNumberOfFines = data.question38TotalNumberOfLines;
        })
        .finally(() => {
          this.$emit("form-state-changed", false);
          this.loadingData = false;
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b12_38_numberOfConvictions: this.b12_38_numberOfConvictions,
        b12_38_totalNumberOfFines: this.b12_38_totalNumberOfFines,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      // Send data
      const formData = {
        question38NumberOfConvictions: this.b12_38_numberOfConvictions,
        question38TotalNumberOfLines: this.b12_38_totalNumberOfFines,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b12", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },

    validateFormData(formData) {
      const errors = {};

      if (isNaN(formData.b12_38_numberOfConvictions)) {
        errors.b12_38_numberOfConvictions = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b12_38_totalNumberOfFines)) {
        errors.b12_38_totalNumberOfFines = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }

      return errors;
    },
  },
  watch: {
    b12_38_numberOfConvictions: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b12_38_totalNumberOfFines: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
