<template>
  <div class="d-flex">
    <v-spacer></v-spacer>
    <v-scroll-x-reverse-transition>
      <v-btn
        v-if="changesSaved"
        text
        color="grey"
        class="text-none"
        x-large
        disabled
        >Changes were saved</v-btn
      >
    </v-scroll-x-reverse-transition>
    <v-btn
      class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
      color="#F25D3B"
      elevation="0"
      tile
      x-large
      @click="click()"
      :loading="loading"
    >
      {{ $t("common.actions.save") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    changesSaved: Boolean,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped></style>
