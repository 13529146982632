<template>
  <v-row>
    <v-col>
      <div class="black--text body-2 mb-2 d-flex flex-row">
        <strong class="flex-grow-1">{{ fieldLabel }}</strong>
        <v-btn v-if="deletable" color="#f25d3b" @click="$emit('delete')" icon><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-textarea
        v-model="internalText"
        filled
        :rows="rows"
        :counter="counter"
        :error-messages="errorMessage"
        :placeholder="placeholder"
      >
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: String,
    fieldLabel: String,
    counter: Number,
    errorMessage: String,
    placeholder: String,
    rows: {
      type: [String, Number],
      default: "8",
    },
    deletable: {
        type: Boolean,
        default: false,
    }
  },
  computed: {
    internalText: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
