var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-8 font-weight-regular text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b1.title"))+" ")]),(_vm.loadingData)?_c('v-container',{attrs:{"fluid":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#f25d3b"}})],1):_c('v-container',{attrs:{"fluid":""}},[_c('ESGRadioButtons',{attrs:{"title":_vm.$t('pages.esg.report.b1.21a.label'),"description":_vm.$t('pages.esg.report.b1.21a.description'),"options":_vm.b1_21aOptions,"errorMessage":_vm.formErrors.b1_21a},model:{value:(_vm.b1_21a),callback:function ($$v) {_vm.b1_21a=$$v},expression:"b1_21a"}}),_c('ESGRadioButtons',{attrs:{"title":_vm.$t('pages.esg.report.b1.21b.label'),"description":_vm.$t('pages.esg.report.b1.21b.description'),"options":_vm.b1_21bOptions,"errorMessage":_vm.formErrors.b1_21b},model:{value:(_vm.b1_21b),callback:function ($$v) {_vm.b1_21b=$$v},expression:"b1_21b"}}),(_vm.b1_21b === _vm.consolidatedBasis)?_c('ESGTableRowEditor',{attrs:{"columns":[
        {
          name: _vm.$t('pages.esg.report.b1.21c.subsidiaryName'),
          key: 'name',
        },
        {
          name: _vm.$t('pages.esg.report.b1.21c.registeredAddress'),
          key: 'address',
        },
      ],"errorMessage":_vm.formErrors.subsidiaries,"errorsOnLines":_vm.formErrors.subsidiariesLines},model:{value:(_vm.subsidiaries),callback:function ($$v) {_vm.subsidiaries=$$v},expression:"subsidiaries"}}):_vm._e(),_c('ESGConfirmButton',{attrs:{"loading":_vm.submittingData,"changesSaved":_vm.changesSaved},on:{"click":function($event){return _vm.save()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }