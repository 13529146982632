<template>
  <AmountChangeCard
    :title="selectedDynamicFocusField?.name"
    :iconBackColor="selectedDynamicFocusField?.iconBackgroundColor"
    :hideBenchmark="true"
    :value="selectedDynamicFocusField?.value()"
    :unit="selectedDynamicFocusField?.unit()"
    :bars="selectedDynamicFocusField?.bars()"
    :dial="selectedDynamicFocusField?.dial()"
    :intensity="selectedDynamicFocusField?.intensity()"
    :percentChange="null"
    :sinceNumberOfDays="null"
  >
    <template #top-append>
      <v-menu v-if="dynamicFocusFieldOptions?.length > 0" offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            light
            plain
            small
            elevation="0"
            v-bind="attrs"
            v-on="on"
            class="align-self-center px-0 justify-end"
            width="30px"
            min-width="30px"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item
            v-for="(option, index) in dynamicFocusFieldOptions"
            :key="index"
            @click="$emit('changed-dynamic-focus', option.key)"
          >
            <v-list-item-title>{{ option.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:icon>
      <component
        :is="selectedDynamicFocusField?.iconComponentKey"
        style="width: 20px; height: 20px"
      ></component>
    </template>
  </AmountChangeCard>
</template>

<script>
import {
  currencyFormatter,
  integerNumberFormatter,
} from "../../util/formatters";
import AmountChangeCard from "../Cards/AmountChangeCard.vue";

import Tree from "../../../src/assets/svg/tree.svg";
import DonateCoin from "../../../src/assets/svg/donate-coin.svg";
import Package from "../../../src/assets/svg/package.svg";
import Leaf from "../../../src/assets/svg/leaf.svg";
import Formatters from "../../mixins/Formatters.vue";

export default {
  components: { AmountChangeCard, Tree, DonateCoin, Package, Leaf },
  mixins: [Formatters],
  props: {
    assetCardData: Object,
    selectedDynamicFocusFieldKey: null,
  },
  data() {
    return {
      staticDynamicFocusFieldOptions: [
        {
          name: this.$t("components.cards.titles.entries"),
          key: "entries",
          iconComponentKey: "Package",
          iconBackgroundColor: "#6493BE1f",
          value: () =>
            integerNumberFormatter.format(
              this.assetCardData?.accountingEntriesCount?.value ?? 0
            ),
          unit: () => {},
          bars: () => {},
          dial: () => {},
          intensity: () => {},
        },
        {
          name: this.$t("components.cards.titles.files"),
          key: "files",
          iconComponentKey: "Package",
          iconBackgroundColor: "#6493BE1f",
          value: () =>
            integerNumberFormatter.format(
              this.assetCardData?.totalNumberOfFiles?.value ?? 0
            ),
          unit: () => {},
          bars: () => {},
          dial: () => {},
          intensity: () => {},
        },
        {
          name: this.$t("components.cards.titles.purchases"),
          key: "purchases",
          iconComponentKey: "Package",
          iconBackgroundColor: "#6493BE1f",
          value: () =>
            integerNumberFormatter.format(
              this.assetCardData?.numberOfAssetsCard?.value ?? 0
            ),
          unit: () => {},
          bars: () => this.getAssetCountBars(),
          dial: () => {},
          intensity: () => {},
        },
        {
          name: this.$t("components.cards.titles.totalCo2"), //"Total CO2 emissions",
          key: "total_co2_emissions",
          iconComponentKey: "Leaf",
          iconBackgroundColor: "#E5EDEC",
          value: () => this.totalCo2Used()?.value,
          unit: () => this.totalCo2Used()?.unit,
          bars: () => this.getScopeCountBars(),
          dial: () => {},
          intensity: () => {},
        },
        {
          name: this.$t("components.cards.titles.totalSpentAmount"),
          key: "total_purchase_amount",
          iconComponentKey: "DonateCoin",
          iconBackgroundColor: "#EDDDE6",
          value: () => this.totalSpentAmount(),
          unit: () => this.totalSpentAmountUnit(),
          bars: () => {},
          dial: () => {},
          intensity: () => {},
        },
        {
          name: this.$t("components.cards.titles.trees"),
          key: "trees_needed_to_offset_emissions",
          iconComponentKey: "Tree",
          iconBackgroundColor: "#E5EDEC",
          value: () => this.treesToOffset(),
          unit: () => this.$t("common.units.trees"),
          bars: () => {},
          dial: () => {},
          intensity: () => {},
        },
      ],
      dynamicFocusFieldOptionsIntensityFigures: [],
    };
  },
  methods: {
    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1 && value !== 0) {
        return 0.1;
      }

      // Else return value
      return value;
    },
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
    totalSpentAmount() {
      // get amount from assetCardData
      let total = this.assetCardData?.totalAmountSpent?.value ?? 0;

      return currencyFormatter.format(total);
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData?.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData?.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData?.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "0";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;
      return this.formatEmissions(totalCo2);
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData?.scope1Emissions;
        const scope2 = this.assetCardData?.scope2Emissions;
        const scope3 = this.assetCardData?.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#6493BE",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#E35E3E",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    totalSpentAmountUnit() {
      return this.assetCardData?.totalAmountSpent?.unit;
    },
  },
  computed: {
    selectedDynamicFocusField() {
      const dynamicField = this.dynamicFocusFieldOptions.find(
        (option) => option.key === this.selectedDynamicFocusFieldKey
      );
      if (dynamicField) {
        return dynamicField;
      }

      // Return empty card if no match
      return {
        name: " ",
        key: " ",
        iconComponentKey: "",
        iconBackgroundColor: "#6493BE1f",
        value: () => 0,
        unit: () => {},
        bars: () => {},
        dial: () => {},
        intensity: () => {},
      };
    },
    dynamicFocusFieldOptions() {
      return [
        ...this.staticDynamicFocusFieldOptions,
        ...this.dynamicFocusFieldOptionsIntensityFigures,
      ];
    },
  },
  watch: {
    assetCardData: {
      deep: true,
      immediate: true,
      handler: function (val) {
        const intensityFigures = val?.intensityFigures?.map(
          (intensityFigure) => {
            return {
              name:
                this.$t("components.cards.titles.intensityFigure") +
                " - " +
                intensityFigure.name,
              key: "intensityFigure_" + intensityFigure.id,
              iconComponentKey: "Package",
              iconBackgroundColor: "#6493BE1f",
              value: () =>
                currencyFormatter.format(intensityFigure.calculatedFigure),
              unit: () => this.$t("common.units.kgCO2e"),
              bars: () => {},
              dial: () => {},
              intensity: () => {},
            };
          }
        );
        intensityFigures?.forEach((intensityFigure) => {
          this.dynamicFocusFieldOptionsIntensityFigures.push(intensityFigure);
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
