<template>
  <v-list flat class="py-1 navigation-list" :class="mini ? 'mini' : 'expanded'">
    <v-list-item-group v-model="selectedMenu">
      <template v-for="(item, index) in filteredItems">
        <div
          class="navigation-heading px-4"
          :style="
            mini
              ? 'font-size: 10px'
              : 'font-size: 14px; padding-left: 16px; margin-left: 15px;'
          "
          v-if="item.type && item.type === 'heading'"
          :key="
            item.type && item.type === 'heading'
              ? 'heading-' + index
              : item.route
          "
        >
          {{ item.title }}
        </div>
        <v-list-group
          v-else
          :key="item.route"
          :ref="'list-groups'"
          :append-icon="null"
          :href="handleMainRoute(item)"
          class="v-list-item--dense"
          @click="visit(item)"
          :value="isOnRoute(item)"
        >
          <template v-slot:activator>
            <v-list-item-title
              :to="item.route"
              class="d-flex flex-row align-center"
              :class="item.subRoutes ? 'top-level-group' : null"
            >
              <div
                v-if="item.iconComponent"
                class="menu-item-icon"
                style="margin: 0px 15px"
                :class="isOnRoute(item) ? 'highlighted-icon' : ''"
              >
                <component :is="item.iconComponent"></component>
              </div>
              <div
                class="block py-5 flex-grow-1"
                :class="isOnRoute(item) ? 'is-on-route' : ''"
              >
                {{ item.title }}
              </div>
              <span
                class="red-dot"
                :class="mini ? 'red-dot-mini' : null"
                v-if="shouldNotify(item)"
              ></span>
              <v-icon
                v-if="!mini && item.subRoutes"
                :color="isOnRoute(item) || true ? '#F25D3B' : '#F25D3B80'"
                >mdi-chevron-down</v-icon
              >
            </v-list-item-title>
          </template>
          <v-divider v-if="item.subRoutes" class="navigation-divider" />
          <v-list-item
            v-for="sub in item.subRoutes"
            :key="sub.route"
            v-inertia
            link
            dense
            active-class=""
            :href="handleSubRoute(sub)"
          >
            <v-list-item-content>
              <v-list-item-title
                class="py-2 d-flex"
                :class="isOnRoute(sub) ? 'white--text' : ''"
              >
                <div
                  v-if="sub.iconComponent"
                  class="menu-item-icon"
                  style="margin: 0px 15px"
                  :class="isOnRoute(sub) ? 'highlighted-icon' : ''"
                >
                  <component :is="sub.iconComponent"></component>
                </div>
                <div class="flex-grow-1">
                  {{ sub.title }}
                </div>
                <span class="red-dot" v-if="shouldNotify(sub)"></span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="item.subRoutes" class="navigation-divider" />
        </v-list-group>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import DashboardIcon from "../../src/assets/svg/dashboard.svg";
import TargetIcon from "../../src/assets/svg/target.svg";
import AssetsIcon from "../../src/assets/svg/assets.svg";
import ReportIcon from "../../src/assets/svg/report.svg";
import SolutionsIcon from "../../src/assets/svg/solutions.svg";
import SupportIcon from "../../src/assets/svg/support.svg";
import UserManagementIcon from "../../src/assets/svg/user-management.svg";
import DocumentIcon from "../../src/assets/svg/document.svg";
import Document2Icon from "../../src/assets/svg/document-2.svg";
import SettingsIcon from "../../src/assets/svg/settings.svg";
import AuditIcon from "../../src/assets/svg/audit.svg";
import GlobalDateRange from "../mixins/GlobalDateRange.vue";
import AuthHelpers from "../mixins/AuthHelpers.vue";

export default {
  mixins: [GlobalDateRange, AuthHelpers],
  props: {
    url: String,
    mini: Boolean,
    isOrganizationGroup: Boolean,
  },
  components: {
    DashboardIcon,
    TargetIcon,
    AssetsIcon,
    ReportIcon,
    SolutionsIcon,
    UserManagementIcon,
    SettingsIcon,
    SupportIcon,
    AuditIcon,
    DocumentIcon,
    Document2Icon,
  },
  computed: {
    selectedMenu: {
      get() {
        return this.url;
      },
      set() {
        //redirects
      },
    },
    items() {
      return [
        {
          title: "",
          permissions: "Users",
          type: "heading",
          route: "",
        },
        {
          title: this.$t("components.menuItems.audit"),
          route: "audits.index",
          permission: "BackofficeSettings",
          iconComponent: "audit-icon",
          roles: ["ADMIN"],
        },
        {
          title: this.$t("components.menuItems.agreements"),
          route: "agreements.index",
          iconComponent: "document-2-icon",
        },
        /*{
          title: this.$t("components.menuItems.documents"),
          iconComponent: "document-2-icon",
          route: "documents.index",
        },*/
        {
          title: this.$t("components.menuItems.reports"),
          iconComponent: "report-icon",
          route: "report.index",
        },
        {
          title: this.$t("components.menuItems.support"),
          route: "support.index",
          iconComponent: "support-icon",
        },
        {
          title: "",
          permissions: "Users",
          type: "heading",
          route: "",
        },
      ];
    },
    availableItems() {
      const roleName = this.getCurrentUserRoleName();

      const itemFilter = (i) =>
        (!i.permission || this.currentUserHasClaim(i.permission)) &&
        (!i.roles || i.roles.includes(roleName));

      let items = this.items.filter(itemFilter);

      items.forEach((item) => {
        if (item.subRoutes) {
          item.subRoutes = item.subRoutes.filter(itemFilter);
        }
      });

      return items;
    },
    filteredItems() {
      if (this.isOrganizationGroup) {
        // Organization groups are only supported on a subset of pages
        return this.availableItems.filter((item) => {
          return ["dashboard.index", "report.index", "settings.index"].includes(
            item.route
          );
        });
      } else {
        return this.availableItems;
      }
    },
    user() {
      return this.$inertia.page.props.auth.user;
    },
  },
  data() {
    return {
      dateRange: { from: null, to: null },
    };
  },
  mounted() {
    this.syncDateRange((dateRange) => {
      this.dateRange = dateRange;
    });
  },
  watch: {
    mini(to) {
      if (to) {
        this.$refs["list-groups"].forEach((item) => {
          item.isActive = false;
        });
      }
    },
    assets: {
      handler() {
        // Rerender component to add correct daterange in url. See handleSubRoute()
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  methods: {
    handleMainRoute(main) {
      if (main.route === "assets.index") {
        return this.route(main.route, {
          ...this.calculateFilters(main.filter),
          page: 1,
        });
      }

      if (main.route === "audits.index") {
        return this.route(main.route, {
          fromDate: this.dateRange.from,
          toDate: this.dateRange.to,
        });
      }

      return main.filter != null
        ? this.route(main.route, this.calculateFilters(main.filter))
        : this.route(main.route);
    },
    handleSubRoute(sub) {
      if (sub.route === "assets.index") {
        return this.route(sub.route, {
          ...this.calculateFilters(sub.filter),
          page: 1,
        });
      }

      if (sub.route === "audits.index") {
        return this.route(sub.route, {
          fromDate: this.dateRange.from,
          toDate: this.dateRange.to,
        });
      }

      return sub.filter != null
        ? this.route(sub.route, this.calculateFilters(sub.filter))
        : this.route(sub.route);
    },
    calculateFilters(filterType) {
      var searchParams = new URLSearchParams(window.location.search);
      if (filterType == "filters") {
        return {
          filters: {
            organizationId:
              searchParams.get("organizationId") ??
              searchParams.get("filters[organizationId]"),
          },
        };
      }
      if (filterType == "flat") {
        return {
          organizationId:
            searchParams.get("organizationId") ??
            searchParams.get("filters[organizationId]"),
        };
      }
    },
    isOnRoute(item) {
      if (item.subRoutes != null) {
        let onRoute = false;
        item.subRoutes.forEach((sub) => {
          if (this.isOnRoute(sub)) {
            onRoute = true;
          }
        });
        return onRoute;
      }
      if (this.$page.url.substr(1).toLowerCase() == "") {
        return item.route.split(".")[0] == "dashboard";
      }

      const page = this.$page.url.substr(1).toLowerCase();

      if (page.includes("assets/more")) {
        if (item.route === "assets.more") {
          return true;
        }
        return false;
      }

      if (page.includes("assets")) {
        if (item.route === "assets.index") {
          return true;
        }
        return false;
      }

      return this.$page.url
        .substr(1)
        .toLowerCase()
        .startsWith(item.route.split(".")[0]);
    },
    shouldNotify() {
      // There are currently no uses for notifications. Use Inertia shared data resolvers to pass
      // information about when to notify the user
      return false;
    },
    visit(item) {
      if (item?.subRoutes?.length > 0) return;

      setTimeout(() => {
        if (item.route === "assets.index") {
          if (item.filter != null) {
            this.$inertia.get(
              this.route(item.route, {
                ...this.calculateFilters(item.filter),
                page: 1,
                postFilters: {},
              })
            );
          } else {
            this.$inertia.get(this.route(item.route));
          }
          return;
        }

        if (item.route === "audits.index") {
          this.$inertia.get(
            this.route(item.route, {
              fromDate: this.dateRange.from,
              toDate: this.dateRange.to,
            })
          );
          return;
        }

        item.filter != null
          ? this.$inertia.get(
              this.route(item.route, this.calculateFilters(item.filter))
            )
          : this.$inertia.get(this.route(item.route));
      }, 150);
    },
    getMenuItemIcon(icon) {
      if (icon === "dashboard") {
        return "";
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.highlighted {
  background: rgba(255, 255, 255, 0);
}

.v-list-item__title {
  color: rgba(255, 255, 255, 0.5);
}

.v-list-item__title:hover {
  color: #fff !important;
}

.v-list-item--dense {
  background-color: transparent !important;
  background: transparent !important;
}

.is-on-route {
  color: #fff;
}

.indented {
  margin-left: 36px;
}

.red-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: red;
  width: 10px;
  height: 10px;
}

.red-dot-mini {
  position: absolute;
  top: 10px;
  right: 10px;
}

.v-list-item--dense >>> .v-list-group__header {
  min-height: 40px;
  background-color: rgba(0, 0, 0, 0) !important;
}

.v-list-group--active .v-list-item__title .mdi-chevron-down {
  transform: rotateZ(180deg);
}

.menu-item-icon svg path {
  fill: rgba(255, 255, 255, 0.5);
}

.menu-item-icon {
  color: #fff !important;
}

.highlighted-icon svg path {
  fill: #fff;
}

.navigation-heading {
  text-transform: uppercase;
  margin-top: 45px;
  margin-bottom: 10px;
  color: #ffffff80;
  transition: font-size 0.3s;
}

.navigation-list.expanded .top-level-group {
  background: #f25d3b26;
  margin: 5px 0;
  padding: 0 5px;
  border-radius: 12px;
  padding-right: 9px;
}

.navigation-list.expanded :deep(.v-list-item) {
  padding: 0 8px;
}

.navigation-list :deep(.v-list-group__items) hr {
  display: none;
}
</style>
