<template>
  <v-card
    light
    color="#fff"
    elevation="0"
    rounded="0"
    max-width="100%"
    class="flex-grow-1"
  >
    <div class="pl-5 pr-5 py-4">
      <div class="d-flex flex-row align-center">
        <v-list-item-avatar
          :color="iconBackColor"
          class="d-flex align-center justify-center items-center"
        >
          <slot name="icon"></slot>
        </v-list-item-avatar>
        <div class="body-1 grey--text text--darken-1 flex-grow-1">
          {{ title }}
        </div>
        <TinyBarChart v-if="bars" :data="bars" />
        <TinyDial v-else-if="dial" :data="dial"></TinyDial>
        <TinyIntensityDial
          v-else-if="intensity"
          :data="intensity"
        ></TinyIntensityDial>

        <slot name="top-append"></slot>
      </div>
      <div class="d-flex flex-row align-center">
        <div class="value flex-grow-1 pr-1">
          {{ value }}
          <span class="grey--text caption" v-if="unit">{{ unit }}</span>
        </div>
        <div class="caption" v-if="!hideBenchmark">
          <div
            class="d-flex align-center"
            :class="percentChange >= 0 ? 'green--text' : 'red--text'"
          >
            <v-icon v-if="percentChange > 0" color="green" size="16">
              {{ "mdi-arrow-top-right" }}
            </v-icon>
            <v-icon v-else-if="percentChange < 0" color="red" size="16">
              {{ "mdi-arrow-bottom-left" }}
            </v-icon>
            <span>{{ Math.abs(percentChange) }}%</span>
          </div>
          <div class="grey--text text--lighten-1">
            {{ $t("components.cards.amountChange.last") }}
            {{ sinceNumberOfDays }}
            {{ $t("components.cards.amountChange.days") }}
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import TinyBarChart from "../Outputs/TinyBarChart.vue";
import TinyDial from "../Outputs/TinyDial.vue";
import TinyIntensityDial from "../Outputs/TinyIntensityDial.vue";

export default {
  components: { TinyBarChart, TinyDial, TinyIntensityDial },
  props: {
    title: String,
    iconBackColor: String,
    value: [String, Number],
    unit: String,
    percentChange: Number,
    sinceNumberOfDays: String,
    hideBenchmark: Boolean,
    bars: Array,
    dial: Object,
    intensity: Object,
  },
};
</script>

<style lang="css" scoped>
.value {
  font-size: 32px;
}
</style>
