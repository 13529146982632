<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b8.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b8.34.questionNumber") }}
      </ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b8.34.questionHeader" tag="span"
          ><span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b8.34.employees")
          }}</span></i18n
        >
      </ESGText>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34.inputUnit')"
        :errorMessage="formErrors.b8_34"
        v-model="b8_34"
      ></ESGTextField>
      <!-- 34a -->
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b8.34a.questionNumber") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b8.34a.questionHeader") }}
      </ESGText>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34a.temporary.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34a.temporary.inputUnit')"
        :errorMessage="formErrors.b8_34a_temporary"
        v-model="b8_34a_temporary"
      ></ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34a.permanent.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34a.permanent.inputUnit')"
        :errorMessage="formErrors.b8_34a_permanent"
        v-model="b8_34a_permanent"
      ></ESGTextField>
      <!-- 34b -->
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b8.34b.questionNumber") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b8.34b.questionHeader") }}
      </ESGText>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34b.male.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34b.male.inputUnit')"
        :errorMessage="formErrors.b8_34b_male"
        v-model="b8_34b_male"
      ></ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34b.female.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34b.female.inputUnit')"
        :errorMessage="formErrors.b8_34b_female"
        v-model="b8_34b_female"
      ></ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34b.other.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34b.other.inputUnit')"
        :errorMessage="formErrors.b8_34b_other"
        v-model="b8_34b_other"
      ></ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b8.34b.notRegistered.questionText')"
        :inputUnit="$t('pages.esg.report.b8.34b.notRegistered.inputUnit')"
        :errorMessage="formErrors.b8_34b_notRegistered"
        v-model="b8_34b_notRegistered"
      ></ESGTextField>
      <!--34c-->
      <ESGTableRowEditor
        :title="$t('pages.esg.report.b8.34c.questionNumber')"
        :description="$t('pages.esg.report.b8.34c.questionHeader')"
        v-model="employeesByCountry"
        :columns="[
          {
            name: $t('pages.esg.report.b8.34c.country'),
            key: 'country',
          },
          {
            name: $t('pages.esg.report.b8.34c.fte'),
            key: 'fte',
          },
        ]"
      ></ESGTableRowEditor>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import { serialize } from "object-to-formdata";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";

export default {
  components: { ESGText, ESGConfirmButton, ESGTextField, ESGTableRowEditor },
  props: {
    reportId: String,
  },
  data() {
    return {
      b8_34: "",
      b8_34a_temporary: "",
      b8_34a_permanent: "",
      b8_34b_male: "",
      b8_34b_female: "",
      b8_34b_other: "",
      b8_34b_notRegistered: "",
      formErrors: {},
      employeesByCountry: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b8",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b8_34 = data.question34;
          this.b8_34a_temporary = data.question34aTemporary;
          this.b8_34a_permanent = data.question34aPermanent;
          this.b8_34b_male = data.question34bMale;
          this.b8_34b_female = data.question34bFemale;
          this.b8_34b_other = data.question34bOther;
          this.b8_34b_notRegistered = data.question34bNotRegistered;
          if (
            data.question34cRows !== null &&
            data.question34cRows.length > 0
          ) {
            this.employeesByCountry = data.question34cRows.map((sub) => {
              return { editable: false, ...sub };
            });
          }
        })
        .finally(() => {
          this.$emit("form-state-changed", false);
          this.loadingData = false; 
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b8_34: this.b8_34,
        b8_34a_temporary: this.b8_34a_temporary,
        b8_34a_permanent: this.b8_34a_permanent,
        b8_34b_male: this.b8_34b_male,
        b8_34b_female: this.b8_34b_female,
        b8_34b_other: this.b8_34b_other,
        b8_34b_notRegistered: this.b8_34b_notRegistered,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      // Send data
      const formData = {
        question34: this.b8_34,
        question34aTemporary: this.b8_34a_temporary,
        question34aPermanent: this.b8_34a_permanent,
        question34bMale: this.b8_34b_male,
        question34bFemale: this.b8_34b_female,
        question34bOther: this.b8_34b_other,
        question34bNotRegistered: this.b8_34b_notRegistered,
        question34cRows: this.employeesByCountry,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b8", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },

    validateFormData(formData) {
      const errors = {};

      const numberFields = {
        b8_34: formData.b8_34,
        b8_34a_temporary: formData.b8_34a_temporary,
        b8_34a_permanent: formData.b8_34a_permanent,
        b8_34b_male: formData.b8_34b_male,
        b8_34b_female: formData.b8_34b_female,
        b8_34b_other: formData.b8_34b_other,
        b8_34b_notRegistered: formData.b8_34b_notRegistered,
      };

      Object.keys(numberFields).forEach((key) => {
        if (isNaN(numberFields[key])) {
          errors[key] = this.$t(
            "pages.esg.report.validation.valueMustBeNumber"
          );
        }
      });
      return errors;
    },
  },
  watch: {
    b8_34: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34a_temporary: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34a_permanent: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34b_male: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34b_female: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34b_other: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b8_34b_notRegistered: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    employeesByCountry: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },

    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
