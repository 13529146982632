<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container fluid class="py-4">
      <v-row class="px-2">
        <v-col cols="12" class="px-2 pt-0">
          <div class="table-filter d-flex flex-row pa-4">
            <v-col cols="3">
              <v-text-field
                :label="$t('pages.reports.index.list.search')"
                color="#686868"
                class="search-field rounded-0 pt-0"
                height="44"
                light
                hide-details="auto"
                background-color="#F7F7F7"
                filled
                solo
                dense
                prepend-inner-icon="mdi-magnify"
                flat
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <div>
              <v-btn
                large
                class="align-self-stretch align-self-sm-center mt-5 mt-sm-0 text-none"
                elevation="0"
                tile
                color="#F25D3B"
                dark
                @click="showModal = true"
              >
                <v-icon class="pr-3 block" color="#fff" small>mdi-plus</v-icon>
                {{ $t("pages.reports.index.create.label") }}
              </v-btn>
            </div>
            <ESGReportForm
              v-model="showModal"
              :settings="settings"
              :esgReport="editingReport"
            ></ESGReportForm>
          </div>
          <v-data-table
            :items-per-page="-1"
            class="report-table"
            :headers="headers"
            :header-props="{ 'sort-icon': 'mdi-unfold-more-horizontal' }"
            :items="reports"
            @dblclick:row="(_, report) => goToReport(report.item)"
          >
            <template #item.name="{ item }">
              <a :href="'/esg/report/' + item.id + '/' + 'b1'">{{
                item.name
              }}</a>
            </template>
            <template #item.dateRange="{ item }">
              <div class="d-flex flex-row align-center">
                {{
                  formatDate(
                    item.dateRange.startDate,
                    settings?.defaultDateFormat
                  )
                }}
                <ArrowRightIcon
                  class="mx-2"
                  width="12px"
                  height="12px"
                ></ArrowRightIcon>
                {{
                  formatDate(
                    item.dateRange.endDate,
                    settings?.defaultDateFormat
                  )
                }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import { formatDate } from "../../util/dateTime";
import ArrowRightIcon from "../../assets/svg/arrow-right-dark-20px.svg";
import ESGReportForm from "./ESGReportForm.vue";

export default {
  components: { ArrowRightIcon, ESGReportForm },
  layout: appLayout({ title: "pages.reports.index.title" }),
  props: {
    settings: Object,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("Id"),
          value: "referenceKey",
        },
        {
          text: this.$t("Name"),
          value: "name",
        },
        {
          text: this.$t("Date range"),
          value: "dateRange",
        },
        {
          text: this.$t("Status"),
          value: "status",
        },
      ],
      reports: [],
      showModal: false,
      editingReport: null,
    };
  },
  mounted() {
    this.getEsgReports();
  },
  methods: {
    formatDate,
    /*editReport(report) {
      this.editingReport = report;
      this.showModal = true;
    },*/
    goToReport(item) {
      const path = "/esg/report/" + item.id + "/" + "b1";

      window.location = path;
    },
    getEsgReports() {
      fetch(this.route("api.esg-reports.index"), {
        method: "GET",
      })
        .then((r) => r.json())
        .then((data) => {
          this.reports = this.formatReports(data);
        });
    },
    formatReports(reports) {
      return reports.map((report) => {
        return {
          id: report.id,
          referenceKey: report.referenceKey,
          dateRange: { startDate: report.startDate, endDate: report.endDate },
          status: report.closedAt
            ? this.$t("pages.esg.table.closed")
            : this.$t("pages.esg.table.active"),
          endDate: report.endDate,
          name: report.reportType,
          reportType: report.reportType,
        };
      });
    },
  },
  computed: {},
  watch: {
    showModal(to) {
      if (!to) {
        this.getEsgReports();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .table-filter {
    background-color: #fff;
  }
}
:deep(.actions-column) {
  width: 0;
}
</style>
