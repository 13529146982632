<template>
  <v-row>
    <v-col v-if="questionNumber" cols="12" class="pb-0"
      ><div class="body-2 grey--text">
        {{ questionNumber }}
      </div></v-col
    >
    <v-col cols="6" class="d-flex">
      <div v-if="$slots.questionText" class="body-2">
        <slot name="questionText"></slot>
      </div>
      <div v-else class="body-2">
        {{ questionText }}
      </div>
    </v-col>
    <v-col cols="6">
      <v-text-field
        outlined
        color="grey"
        height="35px"
        class="my-1 py-0 body-2 text-field"
        v-model="internalValue"
        :error-messages="errorMessage"
        :placeholder="placeholder"
      >
        <template #append>
          <div v-if="inputUnit">
            {{ inputUnit }}
          </div>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    questionNumber: String,
    questionText: String,
    inputUnit: String,
    value: [String, Number],
    errorMessage: String,
    placeholder: String,
  },
  computed: {
    internalValue: {
      get() {
        return this.value; // Bound to the parent's value
      },
      set(value) {
        this.$emit("input", value); // Emit `input` to update the parent's value
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  :deep(.v-input__slot) {
    min-height: unset;
  }
  :deep(.v-input__append-inner) {
    margin-top: 10px;
  }
}
</style>
