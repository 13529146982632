<template>
  <v-tooltip bottom color="#1E1E1E" nudge-bottom="5px">
    <template v-slot:activator="{ on, attrs }">
      <div class="dial-meter" v-bind="attrs" v-on="on">
        <div class="outer">
          <div class="inner">
            <IntensityDialSVG></IntensityDialSVG>
            <div
              class="dial"
              :style="
                'transform: translate(-2px, 2px) rotate(' +
                (180 + 180 * amountPercent) +
                'deg);'
              "
            ></div>
            <div class="dial-hinge"></div>
          </div>
        </div>
      </div>
    </template>
    <div class="tooltip">
      <div>tooltip</div>
    </div>
  </v-tooltip>
</template>

<script>
import IntensityDialSVG from "../../assets/svg/intensity-dial.svg";

export default {
  components: { IntensityDialSVG },
  props: {
    data: Object,
  },
  data() {
    return {
      amount: 4,
      total: 10,
    };
  },
  methods: {},
  computed: {
    amountPercent() {
      return this.amount / this.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.dial-meter {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  .outer {
    width: 80px;
    height: 40px;
    position: relative;
    overflow: hidden;
    .inner {
      position: absolute;
      bottom: -100%;
      width: calc(100% - 10px);
      aspect-ratio: 1;
      border-radius: 100%;
      margin: 5px;
      border: 3px solid #737373;

      .step {
        height: 4px;
        left: 50%;
        width: 35px;
        transform-origin: 1px 1px;
        top: calc(50%);
        position: absolute;
      }
      .dial {
        transform-origin: 2px 2px;
        left: 50%;
        height: 4px;
        width: 30px;
        background: #515151;
        position: absolute;
        bottom: 50%;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        transition-duration: 245ms;
      }
      .dial-hinge {
        width: 10px;
        height: 10px;
        background: #515151;
        border-radius: 100%;
        position: absolute;
        left: calc(50% - 5px);
        bottom: calc(50% - 5px);
      }
    }
  }
}

.tooltip {
  &:before {
    content: "";
    height: 17px;
    width: 17px;
    background: #1e1e1e;
    display: block;
    position: absolute;
    left: calc(50%);
    top: 0;
    transform: translate(-50%, -45%) rotate(-45deg) skew(10deg, 10deg);
    border-radius: 2px;
  }
  .rendered-value-row {
    margin: 5px 0;
    .rendered-color {
      height: 14px;
      width: 14px;
      border-radius: 4px;
    }
    .rendered-percentage {
      min-width: 55px;
      text-align: right;
      color: #b4b4b4;
    }
  }
}
</style>
