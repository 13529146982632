<template>
  <v-tooltip bottom color="#1E1E1E" nudge-bottom="5px">
    <template v-slot:activator="{ on, attrs }">
      <div class="dial-meter" v-bind="attrs" v-on="on">
        <div class="outer">
          <div class="inner">
            <div v-for="step in Array(31)" :key="step" class="step"></div>
            <div
              class="dial"
              :style="
                'transform: translate(-0.5px, 0.5px) rotate(' +
                (180 + 180 * amountPercent) +
                'deg);'
              "
            ></div>
            <div class="dial-hinge"></div>
          </div>
        </div>
      </div>
    </template>
    <div class="tooltip">
      <div>tooltip</div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      amount: 21,
      total: 30,
    };
  },
  methods: {},
  computed: {
    amountPercent() {
      return this.amount / this.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.dial-meter {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  .outer {
    width: 80px;
    height: 40px;
    position: relative;
    overflow: hidden;
    .inner {
      position: absolute;
      bottom: -100%;
      width: calc(100% - 10px);
      aspect-ratio: 1;
      border-radius: 100%;
      margin: 5px;
      border: 1px solid #898989;
      .step {
        background: #898989;
        height: 1px;
        width: 2px;
        transform-origin: 34px 0.5px;
        left: -3px;
        top: calc(50%);
        position: absolute;
      }
      @for $i from 1 through 31 {
        .step:nth-child(#{$i}) {
          transform: translate(3px, -1px) rotate(#{6 * ($i - 1)}deg);
        }
      }
      .step:nth-child(5n + 1) {
        width: 4px;
      }
      .dial {
        transform-origin: 0.5px 0.5px; // 0.5 * h
        left: 50%;
        height: 1px;
        width: 30px;
        background: #e35d3e;
        position: absolute;
        bottom: calc(50% + 0.5px);
        transition-duration: 245ms;
      }
      .dial-hinge {
        width: 10px;
        height: 10px;
        background: #e35d3e;
        border-radius: 100%;
        position: absolute;
        left: calc(50% - 5px);
        bottom: calc(50% - 5px);
      }
    }
  }
}

.tooltip {
  &:before {
    content: "";
    height: 17px;
    width: 17px;
    background: #1e1e1e;
    display: block;
    position: absolute;
    left: calc(50%);
    top: 0;
    transform: translate(-50%, -45%) rotate(-45deg) skew(10deg, 10deg);
    border-radius: 2px;
  }
  .rendered-value-row {
    margin: 5px 0;
    .rendered-color {
      height: 14px;
      width: 14px;
      border-radius: 4px;
    }
    .rendered-percentage {
      min-width: 55px;
      text-align: right;
      color: #b4b4b4;
    }
  }
}
</style>
