<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b5.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText>
        <i18n path="pages.esg.report.b5.description" tag="span">
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b5.biodiversity")
          }}</span>
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b5.landUse")
          }}</span>
        </i18n>
      </ESGText>
      <ESGTableRowEditor
        :title="$t('pages.esg.report.b5.28.label')"
        v-model="b5_28"
        :errorMessage="formErrors.b5_28"
        :errorsOnLines="formErrors.b5_28Lines"
        :columns="[
          {
            name: $t('pages.esg.report.b5.28.columns.location'),
            key: 'location',
          },
          {
            name: $t('pages.esg.report.b5.28.columns.area'),
            key: 'area',
            inputUnit: 'ha',
          },
          {
            name: $t('pages.esg.report.b5.28.columns.sensitiveArea'),
            key: 'sensitiveArea',
          },
          {
            name: $t('pages.esg.report.b5.28.columns.natureOrientedArea'),
            key: 'natureOrientedArea',
          },
        ]"
      >
        <template #description>
          <i18n path="pages.esg.report.b5.28.description" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b5.biodiversity")
            }}</span>
          </i18n>
        </template>
      </ESGTableRowEditor>
      <ESGTableRowEditor
        :title="$t('pages.esg.report.b5.29.label')"
        v-model="b5_29"
        staticRows
        :errorMessage="formErrors.b5_29"
        :errorsOnLines="formErrors.b5_29Lines"
        :columns="[
          {
            name: $t('pages.esg.report.b5.29.columns.totalUseOfLand'),
            key: 'totalUseOfLand',
            inputUnit: 'ha',
          },
          {
            name: $t('pages.esg.report.b5.29.columns.totalSealedArea'),
            key: 'totalSealedArea',
            inputUnit: 'ha',
          },
          {
            name: $t('pages.esg.report.b5.29.columns.totalNatureAreaOnSite'),
            key: 'totalNatureOrientedAreaOnSite',
            inputUnit: 'ha',
          },
          {
            name: $t('pages.esg.report.b5.29.columns.totalNatureAreaOffSite'),
            key: 'totalNatureOrientedAreaOffSite',
            inputUnit: 'ha',
          },
        ]"
      >
        <template #description>
          <i18n path="pages.esg.report.b5.29.description" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b5.landUse")
            }}</span>
          </i18n>
        </template>
      </ESGTableRowEditor>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import { serialize } from "object-to-formdata";
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";

export default {
  components: { ESGTableRowEditor, ESGConfirmButton, ESGText },
  props: {
    reportId: String,
  },
  data() {
    return {
      b5_28: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      b5_29: Array(1)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      formErrors: "",
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;

      // Get data here when endpoint is ready
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b5",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.question28Rows !== null && data.question28Rows.length > 0) {
            this.b5_28 = data.question28Rows.map((sub) => {
              return { editable: false, ...sub };
            });
          }

          if (data.question29Rows !== null) {
            this.b5_29 = [{ editable: false, ...data.question29Rows }];
          }
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b5_28: this.b5_28,
        b5_29: this.b5_29,
      });

      if (Object.keys(errors).length > 0) {
        this.submittingData = false;
        this.formErrors = errors;
        return;
      }

      const formData = {
        question28Rows: this.b5_28,
        question29Rows: this.b5_29[0], // Static row
      };

      // Uncomment when endpoint is ready
      // Send data
      fetch(this.route("api.esg-reports.sections.b5", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateFormData(formData) {
      const errors = {};

      const unconfirmedChanges28 =
        formData.b5_28
          // Add index
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter(
            (sub) =>
              sub.editable &&
              (sub.location ||
                sub.area ||
                sub.sensitiveArea ||
                sub.natureOrientedArea)
          ) ?? [];

      if (unconfirmedChanges28.length > 0) {
        errors.b5_28 = this.$t(
          "pages.esg.report.validation.youHaveUnconfirmedChanges"
        );
        errors.b5_28Lines = unconfirmedChanges28.map((sub) => sub.index);

        return errors;
      }

      // Format
      const wrongFormat28 =
        formData.b5_28
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter((sub) => isNaN(sub.area)) ?? [];

      if (wrongFormat28.length > 0) {
        errors.b5_28 = this.$t("pages.esg.report.validation.wrongFormat");
        errors.b5_28Lines = wrongFormat28.map((sub) => sub.index);
        return errors;
      }

      const unconfirmedChanges =
        formData.b5_29
          // Add index
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter(
            (sub) =>
              sub.editable &&
              (sub.totalUseOfLand ||
                sub.totalSealedArea ||
                sub.totalNatureOrientedAreaOnSite ||
                sub.totalNatureOrientedAreaOffSite)
          ) ?? [];

      if (unconfirmedChanges.length > 0) {
        errors.b5_29 = this.$t(
          "pages.esg.report.validation.youHaveUnconfirmedChanges"
        );
        errors.b5_29Lines = unconfirmedChanges.map((sub) => sub.index);

        return errors;
      }

      // Format
      const wrongFormat29 =
        formData.b5_29
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter(
            (sub) =>
              isNaN(sub.totalUseOfLand) ||
              isNaN(sub.totalSealedArea) ||
              isNaN(sub.totalNatureOrientedAreaOnSite) ||
              isNaN(sub.totalNatureOrientedAreaOffSite)
          ) ?? [];

      if (wrongFormat29.length > 0) {
        errors.b5_29 = this.$t("pages.esg.report.validation.wrongFormat");
        errors.b5_29Lines = wrongFormat29.map((sub) => sub.index);
        return errors;
      }

      return {};
    },
  },
  watch: {
    b5_28: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b5_29: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  :deep(.v-input__slot) {
    min-height: unset;
  }
  :deep(.v-input__append-inner) {
    margin-top: 10px;
  }
}
</style>
