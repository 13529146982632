var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-8 font-weight-regular text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b5.title"))+" ")]),(_vm.loadingData)?_c('v-container',{attrs:{"fluid":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#f25d3b"}})],1):_c('v-container',{attrs:{"fluid":""}},[_c('ESGText',[_c('i18n',{attrs:{"path":"pages.esg.report.b5.description","tag":"span"}},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.$t("pages.esg.report.b5.biodiversity")))]),_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.$t("pages.esg.report.b5.landUse")))])])],1),_c('ESGTableRowEditor',{attrs:{"title":_vm.$t('pages.esg.report.b5.28.label'),"errorMessage":_vm.formErrors.b5_28,"errorsOnLines":_vm.formErrors.b5_28Lines,"columns":[
        {
          name: _vm.$t('pages.esg.report.b5.28.columns.location'),
          key: 'location',
        },
        {
          name: _vm.$t('pages.esg.report.b5.28.columns.area'),
          key: 'area',
          inputUnit: 'ha',
        },
        {
          name: _vm.$t('pages.esg.report.b5.28.columns.sensitiveArea'),
          key: 'sensitiveArea',
        },
        {
          name: _vm.$t('pages.esg.report.b5.28.columns.natureOrientedArea'),
          key: 'natureOrientedArea',
        },
      ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('i18n',{attrs:{"path":"pages.esg.report.b5.28.description","tag":"span"}},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.$t("pages.esg.report.b5.biodiversity")))])])]},proxy:true}]),model:{value:(_vm.b5_28),callback:function ($$v) {_vm.b5_28=$$v},expression:"b5_28"}}),_c('ESGTableRowEditor',{attrs:{"title":_vm.$t('pages.esg.report.b5.29.label'),"staticRows":"","errorMessage":_vm.formErrors.b5_29,"errorsOnLines":_vm.formErrors.b5_29Lines,"columns":[
        {
          name: _vm.$t('pages.esg.report.b5.29.columns.totalUseOfLand'),
          key: 'totalUseOfLand',
          inputUnit: 'ha',
        },
        {
          name: _vm.$t('pages.esg.report.b5.29.columns.totalSealedArea'),
          key: 'totalSealedArea',
          inputUnit: 'ha',
        },
        {
          name: _vm.$t('pages.esg.report.b5.29.columns.totalNatureAreaOnSite'),
          key: 'totalNatureOrientedAreaOnSite',
          inputUnit: 'ha',
        },
        {
          name: _vm.$t('pages.esg.report.b5.29.columns.totalNatureAreaOffSite'),
          key: 'totalNatureOrientedAreaOffSite',
          inputUnit: 'ha',
        },
      ]},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('i18n',{attrs:{"path":"pages.esg.report.b5.29.description","tag":"span"}},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(_vm._s(_vm.$t("pages.esg.report.b5.landUse")))])])]},proxy:true}]),model:{value:(_vm.b5_29),callback:function ($$v) {_vm.b5_29=$$v},expression:"b5_29"}}),_c('ESGConfirmButton',{attrs:{"loading":_vm.submittingData,"changesSaved":_vm.changesSaved},on:{"click":function($event){return _vm.save()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }