<template>
  <dialog-form
    :show-dialog="show"
    @showStateChanged="(val) => (show = val)"
    maxWidth="540px"
  >
    <dialog-header
      :title="
        esgReport != null && esgReport.id != null
          ? $t('pages.esg.form.editEsg')
          : $t('pages.esg.form.newEsg')
      "
    >
    </dialog-header>
    <dialog-form-section-one-col>
      <dialog-form-select-input
        :title="$t('pages.esg.form.esgType')"
        :items="['VSME']"
        v-model="esgType"
      ></dialog-form-select-input>
      <dialog-form-input-wrapper :title="$t('pages.esg.form.dateRange')">
        <date-range-picker
          :fromDate="esgReportDateRange.startDate"
          :toDate="esgReportDateRange.endDate"
          @change="(range) => setDateRange(range)"
          :defaultDateFormat="settings.defaultDateFormat"
          buttonStyle="padding: 0"
        >
          <dialog-form-date-selector
            class="pr-2"
            :value="new Date(esgReportDateRange.startDate)"
            readonly
          ></dialog-form-date-selector>
          <dialog-form-date-selector
            class="pl-2"
            :value="new Date(esgReportDateRange.endDate)"
            readonly
          ></dialog-form-date-selector>
        </date-range-picker>
      </dialog-form-input-wrapper>
    </dialog-form-section-one-col>
    <v-card-actions
      class="py-6 px-6 d-flex flex-column-reverse flex-md-row footer-section"
    >
      <v-spacer></v-spacer>
      <v-btn
        @click="show = false"
        class="text-none px-4 mb-4 mb-md-0 align-self-stretch"
        color="#686868"
        elevation="0"
        text
        tile
        x-large
        :disabled="loading"
      >
        <v-icon class="mr-3" color="#686868">mdi-close</v-icon>
        {{ $t("common.actions.cancel") }}
      </v-btn>
      <v-btn
        class="text-none ml-0 mb-4 mb-md-0 ml-md-4 align-self-stretch white--text"
        color="#F25D3B"
        elevation="0"
        tile
        x-large
        :disabled="loading || !isFormValid"
        :loading="loading"
        @click="createEsgReport()"
      >
        {{ $t("common.actions.save") }}
      </v-btn>
    </v-card-actions>
  </dialog-form>
</template>

<script>
import Vue from "vue";
import DialogForm from "../../Components/Dialog/DialogForm.vue";
import DialogHeader from "../../Components/Dialog/DialogFormHeader.vue";
import DialogFormSectionOneCol from "../../Components/Dialog/DialogFormSectionOneCol.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import DateRangePicker from "../../Components/Inputs/DateRangePicker.vue";
import { DateTime } from "luxon";

export default {
  components: {
    DialogForm,
    DialogHeader,
    DialogFormSectionOneCol,
    DialogFormSelectInput,
    DateRangePicker,
    DialogFormDateSelector,
    DialogFormInputWrapper,
  },
  props: {
    value: Boolean,
    esgReport: Object,
    settings: Object,
  },
  data() {
    return {
      esgReportDateRange: this.defaultDateRange(),
      esgType: "VSME",
      loading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isFormValid() {
      if (
        !this.esgType ||
        !this.esgReportDateRange.startDate ||
        !this.esgReportDateRange.endDate
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    setDateRange(dateRange) {
      Vue.set(this, "esgReportDateRange", {
        startDate: dateRange.from,
        endDate: dateRange.to,
      });
    },
    async createEsgReport() {
      const formData = new FormData();
      formData.append("ReportType", this.esgType.toLowerCase());
      formData.append("Name", this.esgType);
      formData.append("StartDate", this.esgReportDateRange.startDate);
      formData.append("EndDate", this.esgReportDateRange.endDate);

      this.loading = true;

      fetch(this.route("api.esg-reports.create"), {
        method: "POST",
        body: formData,
      })
        .then((r) => r.json())
        .then(() => {
          this.show = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    defaultDateRange() {
      // current calendar year
      const today = DateTime.utc().startOf("day");
      const startOfYear = today.startOf("year").toJSDate();
      const endOfYear = today.endOf("Year").startOf("day").toJSDate();
      const fromDate = new Date(startOfYear).toISOString().split("T")[0];
      const toDate = new Date(endOfYear).toISOString().split("T")[0];
      return {
        startDate: fromDate,
        endDate: toDate,
      };
    },
  },
  watch: {
    esgReport(to) {
      if (to.dateRange.startDate && to.dateRange.endDate) {
        Vue.set(this, "esgReportDateRange", {
          startDate: to.dateRange.startDate,
          endDate: to.dateRange.endDate,
        });
      } else {
        // Fallback value so datepicker doesnt fail when there's no date range
        Vue.set(this, "esgReportDateRange", this.defaultDateRange());
      }
      this.esgType = to.esgType;
    },
  },
};
</script>

<style lang="scss" scoped></style>
