var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"mb-8 font-weight-regular text-h5 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b11.title"))+" ")]),(_vm.loadingData)?_c('v-container',{attrs:{"fluid":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#f25d3b"}})],1):_c('v-container',{attrs:{"fluid":""}},[_c('ESGText',{staticClass:"grey--text body-2 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b11.37.questionNumber"))+" ")]),_c('ESGText',[_c('i18n',{attrs:{"path":"pages.esg.report.b11.37.questionHeader","tag":"span"}},[_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b11.37.valueChain")))]),_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b11.37.affectedCommunities")))]),_c('span',{staticClass:"font-weight-bold font-italic"},[_vm._v(" "+_vm._s(_vm.$t("pages.esg.report.b11.37.consumers")))])])],1),_c('ESGTextArea',{attrs:{"counter":_vm.descriptionCharLimit,"errorMessage":_vm.formErrors.description,"fieldLabel":_vm.$t('pages.esg.report.b11.37.fieldLabel')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('ESGTableRowEditor',{attrs:{"description":_vm.$t('pages.esg.report.b11.other.questionHeader'),"columns":[
        {
          name: _vm.$t('pages.esg.report.b11.other.columns.impact.name'),
          placeholder: _vm.$t(
            'pages.esg.report.b11.other.columns.impact.placeholder'
          ),
          key: 'impact',
        },
        {
          name: _vm.$t('pages.esg.report.b11.other.columns.where.name'),
          placeholder: _vm.$t(
            'pages.esg.report.b11.other.columns.where.placeholder'
          ),
          key: 'where',
        },
        {
          name: _vm.$t('pages.esg.report.b11.other.columns.whoWereAffacted.name'),
          placeholder: _vm.$t(
            'pages.esg.report.b11.other.columns.whoWereAffacted.placeholder'
          ),
          key: 'whoWereAffacted',
        },
      ]},model:{value:(_vm.negativeImpacts),callback:function ($$v) {_vm.negativeImpacts=$$v},expression:"negativeImpacts"}}),_c('ESGConfirmButton',{attrs:{"loading":_vm.submittingData,"changesSaved":_vm.changesSaved},on:{"click":function($event){return _vm.save()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }