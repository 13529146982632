<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b9.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b9.35.questionNumber") }}
      </ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b9.35.questionHeader" tag="span"
          ><span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b9.35.employees")
          }}</span></i18n
        >
      </ESGText>
      <!-- 35a -->
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b9.35a.questionNumber") }}
      </ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b9.35a.questionHeader" tag="span"
          ><span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b9.35a.recordableWorkRelated")
          }}</span></i18n
        >
      </ESGText>
      <ESGTextField
        :questionText="
          $t('pages.esg.report.b9.35a.numberOfAccidents.questionText')
        "
        v-model="b9_35a_NumberOfAccidents"
        :errorMessage="formErrors.b9_35a_NumberOfAccidents"
      ></ESGTextField>
      <ESGTextField
        :questionText="$t('pages.esg.report.b9.35a.rate.questionText')"
        v-model="b9_35a_rate"
        :errorMessage="formErrors.b9_35a_rate"
      ></ESGTextField>

      <ESGText textClass="font-weight-bold">
        <small>{{ $t("pages.esg.report.b9.formular.num") }}</small>
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line1") }}
      </ESGText>
      <ESGText>
        <img
          src="../../../assets/esg-formular-accident-frequency-en.png"
          alt="Accident frequency formular"
          width="650"
        />
      </ESGText>
      <ESGText textClass="font-weight-bold">
        {{ $t("pages.esg.report.b9.formular.line2") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line3") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line4") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line5") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line6") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b9.formular.line7") }}
      </ESGText>

      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import { serialize } from "object-to-formdata";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";

export default {
  components: {
    ESGText,
    ESGConfirmButton,
    ESGTextField,
  },
  props: {
    reportId: String,
  },
  data() {
    return {
      b9_35a_NumberOfAccidents: "",
      b9_35a_rate: "",
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b9",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b9_35a_NumberOfAccidents = data.question35aNumberOfAccidents;
          this.b9_35a_rate = data.question35aRate;
        })
        .finally(() => {
          this.$emit("form-state-changed", false);
          this.loadingData = false;
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b9_35a_NumberOfAccidents: this.b9_35a_NumberOfAccidents,
        b9_35a_rate: this.b9_35a_rate,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      // Send data
      const formData = {
        question35aNumberOfAccidents: this.b9_35a_NumberOfAccidents,
        question35aRate: this.b9_35a_rate,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b9", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });

    },

    validateFormData(formData) {
      const errors = {};

      if (isNaN(formData.b9_35a_NumberOfAccidents)) {
        errors.b9_35a_NumberOfAccidents = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b9_35a_rate)) {
        errors.b9_35a_rate = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }

      return errors;
    },
  },
  watch: {
    b9_35a_NumberOfAccidents: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b9_35a_rate: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
