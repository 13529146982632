<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b10.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <!-- 36 -->
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b10.36.questionNumber") }}
      </ESGText>
      <ESGText>
        {{ $t("pages.esg.report.b10.36.questionHeader") }}
      </ESGText>
      <!-- 36a -->
      <ESGTextField
        :questionNumber="$t('pages.esg.report.b10.36a.questionNumber')"
        v-model="b10_36a"
        :errorMessage="formErrors.b10_36a"
        :placeholder="$t('pages.esg.report.b10.36a.inputPlaceholder')"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b10.36a.questionText" tag="span"
            ><span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b10.employees")
            }}</span
            ><span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b10.wages")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <!-- 36b -->
      <ESGTextField
        :questionNumber="$t('pages.esg.report.b10.36b.questionNumber')"
        v-model="b10_36b"
        :errorMessage="formErrors.b10_36b"
        :placeholder="$t('pages.esg.report.b10.36b.inputPlaceholder')"
        :inputUnit="$t('pages.esg.report.b10.36b.unitType')"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b10.36b.questionText" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b10.pay")
            }}</span>
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b10.employees")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <!-- 36c -->
      <ESGTextField
        :questionNumber="$t('pages.esg.report.b10.36c.questionNumber')"
        v-model="b10_36c"
        :errorMessage="formErrors.b10_36c"
        :placeholder="$t('pages.esg.report.b10.36c.inputPlaceholder')"
        :inputUnit="$t('pages.esg.report.b10.36c.unitType')"
      >
        <template #questionText>
          <i18n path="pages.esg.report.b10.36c.questionText" tag="span">
            <span class="font-weight-bold font-italic">{{
              $t("pages.esg.report.b10.employees")
            }}</span></i18n
          >
        </template>
      </ESGTextField>
      <!-- 36d -->
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b10.36d.questionNumber") }}
      </ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b10.36d.questionHeader" tag="span">
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b10.training")
          }}</span>
          <span class="font-weight-bold font-italic">{{
            $t("pages.esg.report.b10.employee")
          }}</span></i18n
        >
      </ESGText>
      <ESGTextField
        :questionText="
          $t('pages.esg.report.b10.36d.maleEmployees.questionText')
        "
        v-model="b10_36d_male"
        :errorMessage="formErrors.b10_36d_male"
        :placeholder="
          $t('pages.esg.report.b10.36d.maleEmployees.inputPlaceholder')
        "
        :inputUnit="$t('pages.esg.report.b10.36d.maleEmployees.unitType')"
      >
      </ESGTextField>
      <ESGTextField
        :questionText="
          $t('pages.esg.report.b10.36d.femaleEmployees.questionText')
        "
        v-model="b10_36d_female"
        :errorMessage="formErrors.b10_36d_female"
        :placeholder="
          $t('pages.esg.report.b10.36d.femaleEmployees.inputPlaceholder')
        "
        :inputUnit="$t('pages.esg.report.b10.36d.femaleEmployees.unitType')"
      >
      </ESGTextField>
      <ESGTextField
        :questionText="
          $t('pages.esg.report.b10.36d.otherEmployees.questionText')
        "
        v-model="b10_36d_other"
        :errorMessage="formErrors.b10_36d_other"
        :placeholder="
          $t('pages.esg.report.b10.36d.otherEmployees.inputPlaceholder')
        "
        :inputUnit="$t('pages.esg.report.b10.36d.otherEmployees.unitType')"
      >
      </ESGTextField>

      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import { serialize } from "object-to-formdata";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";

export default {
  components: {
    ESGText,
    ESGConfirmButton,
    ESGTextField,
  },
  props: {
    reportId: String,
  },
  data() {
    return {
      b10_36a: "",
      b10_36b: "",
      b10_36c: "",
      b10_36d_male: "",
      b10_36d_female: "",
      b10_36d_other: "",
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b10",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b10_36a = data.question36a;
          this.b10_36b = data.question36b;
          this.b10_36c = data.question36c;
          this.b10_36d_male = data.question36dMaleEmployees;
          this.b10_36d_female = data.question36dFemaleEmployees;
          this.b10_36d_other = data.question36dOtherEmployees;
        })
        .finally(() => {
          this.$emit("form-state-changed", false);
          this.loadingData = false;
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b10_36a: this.b10_36a,
        b10_36b: this.b10_36b,
        b10_36c: this.b10_36c,
        b10_36d_male: this.b10_36d_male,
        b10_36d_female: this.b10_36d_female,
        b10_36d_other: this.b10_36d_other,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      // Send data
      const formData = {
        question36a: this.b10_36a,
        question36b: this.b10_36b,
        question36c: this.b10_36c,
        question36dMaleEmployees: this.b10_36d_male,
        question36dFemaleEmployees: this.b10_36d_female,
        question36dOtherEmployees: this.b10_36d_other,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b10", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });

    },

    validateFormData(formData) {
      const errors = {};

      if (isNaN(formData.b10_36a)) {
        errors.b10_36a = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b10_36b)) {
        errors.b10_36b = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b10_36c)) {
        errors.b10_36c = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b10_36d_male)) {
        errors.b10_36d_male = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b10_36d_female)) {
        errors.b10_36d_female = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }
      if (isNaN(formData.b10_36d_other)) {
        errors.b10_36d_other = this.$t(
          "pages.esg.report.validation.valueMustBeNumber"
        );
      }

      return errors;
    },
  },
  watch: {
    b10_36a: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b10_36b: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b10_36c: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b10_36d_male: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b10_36d_female: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    b10_36d_other: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
