<template>
  <div class="px-8">
    <div class="">
      <dialog-form-input-wrapper title="Fiscal Year">
        <v-row>
          <v-col cols="12">
            <dialog-form-select-input
              style="width: 100%; border-radius: 0"
              v-model="fiscalYearProxy"
              :items="[...fiscalYears, 'Select other date']"
              flat
              dense
              hide-details
              color="#686868"
              background-color="#F7F7F7"
              solo
            ></dialog-form-select-input>
          </v-col>
          <v-col cols="6" v-if="fiscalYearProxy === 'Select other date'">
            <dialog-form-input-wrapper title="Start">
              <dialog-form-date-selector
                @change="(v) => updateFiscalYearFromDate(v)"
              ></dialog-form-date-selector>
            </dialog-form-input-wrapper>
          </v-col>
          <v-col cols="6" v-if="fiscalYearProxy === 'Select other date'">
            <dialog-form-input-wrapper title="End">
              <div class="not-allowed-wrapper">
                <dialog-form-date-selector
                  :value="customToDate ?? null"
                  readonly
                ></dialog-form-date-selector>
              </div>
            </dialog-form-input-wrapper>
          </v-col>
        </v-row>
      </dialog-form-input-wrapper>
      <v-row>
        <v-col cols="6" class="py-0"
          ><dialog-form-select-input
            :title="'Currency'"
            @change="(val) => (defaultCurrencyIdProxy = val)"
            :value="defaultCurrencyIdProxy"
            :items="currencies"
            :item-text="'currencyCode'"
            :item-value="'id'"
          ></dialog-form-select-input
        ></v-col>
        <v-col cols="6" class="py-0"
          ><dialog-form-country-select-input
            :title="'Country'"
            :countries="countries"
            :initialValue="defaultRegion"
            @change="(val) => (defaultRegionProxy = val)"
          ></dialog-form-country-select-input
        ></v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import DialogFormCountrySelectInput from "../../Dialog/inputs/DialogFormCountrySelectInput.vue";
import DialogFormDateSelector from "../../Dialog/inputs/DialogFormDateSelector.vue";
import DialogFormInputWrapper from "../../Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormSelectInput from "../../Dialog/inputs/DialogFormSelectInput.vue";

export default {
  components: {
    DialogFormSelectInput,
    DialogFormCountrySelectInput,
    DialogFormDateSelector,
    DialogFormInputWrapper,
  },
  props: {
    fiscalYears: Array,
    fiscalYear: String,
    depreciationMethods: Array,
    depreciationStarts: Array,
    currencies: Array,
    defaultCurrency: Object,
    countries: Array,
    defaultRegion: String,
  },
  data() {
    return {
      customFromDate: null,
      customToDate: null,
    };
  },
  methods: {
    dateRangeChanged() {},
    updateFiscalYearFromDate(v) {
      const fromDate = new Date(v);

      var d = new Date(fromDate);
      const toDate = new Date(d.setDate(fromDate.getDate() - 1));
      this.customToDate = toDate;

      const value =
        fromDate.toLocaleString("default", { day: "2-digit" }) +
        "/" +
        fromDate.toLocaleString("default", { month: "2-digit" }) +
        " → " +
        toDate.toLocaleString("default", { day: "2-digit" }) +
        "/" +
        toDate.toLocaleString("default", { month: "2-digit" });

      this.$emit("updateForm", "fiscalYear", value);
      this.$emit("update:fiscalYear", value);
    },
  },
  computed: {
    fiscalYearProxy: {
      get() {
        if (
          this.fiscalYear !== "" &&
          !this.fiscalYears.includes(this.fiscalYear)
        ) {
          return "Select other date";
        }

        return this.fiscalYear;
      },
      set(value) {
        this.$emit("updateForm", "fiscalYear", value);
        this.$emit("update:fiscalYear", value);
        this.customFromDate = null;
        this.customToDate = null;
      },
    },
    depreciationMethodProxy: {
      get() {
        return this.depreciationMethods[0];
      },
      set(value) {
        this.$emit("updateForm", "defaultDepreciationMethodId", value);
      },
    },
    depreciationStartProxy: {
      get() {
        return this.depreciationStarts[0];
      },
      set(value) {
        this.$emit("updateForm", "defaultStartOfDepreciationId", value);
      },
    },
    defaultCurrencyIdProxy: {
      get() {
        return this.defaultCurrency?.id ?? null;
      },
      set(value) {
        this.$emit("updateForm", "defaultCurrencyId", value);
      },
    },

    defaultRegionProxy: {
      get() {
        return this.defaultRegion ?? null;
      },
      set(value) {
        this.$emit("updateForm", "defaultRegion", value);
      },
    },
  },
};
</script>

<style scoped>
.not-allowed-wrapper :deep(*) {
  cursor: not-allowed;
  color: #818181 !important;
}
</style>
