<template>
  <v-data-table
    v-model="selectedEntries"
    :items="entriesWithIndex"
    :headers="tableHeaders"
    :footer-props="tableFooterProps"
    item-key="index"
    show-select
    disable-sort
    fixed-header
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
      <v-autocomplete
        :key="header.value"
        :items="entryFieldsWithSkip"
        :value="entryFieldMappings[header.value]"
        :item-disabled="getFieldDisabledStatus"
        @change="handleFieldMappingChanged(header.value, $event)"
        :background-color="
          entryFieldMappings[header.value] === null ? 'yellow' : ''
        "
        class="mb-2"
        style="min-width: 190px"
        dense
        flat
        solo
        hide-details
      ></v-autocomplete>
    </template>
    <template #item="{ item, isSelected, select }">
      <entry-file-mapping-row
        :entry="item.row"
        :is-selected="isSelected"
        :is-edit-mode="isEditMode(item)"
        :field-mappings="entryFieldMappings"
        :column-error-messages="item.columnErrorMessages"
        :column-warning-messages="item.columnWarningMessages"
        :unit-types="unitTypes"
        @toggle-selected="select(!isSelected)"
        @toggle-edit-mode="toggleEditMode(item)"
        @edit="(field, value) => editEntryField(item, field, value)"
        @delete="deleteEntry(item)"
      ></entry-file-mapping-row>
    </template>
    <template #footer.prepend>
      <v-btn text x-small @click="deleteEntryRange(selectedEntries)">
        {{
          $t(
            "components.integrations.manualFileUpload.stepThree.mapping.deleteSelected"
          )
        }}
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { footerProps } from "@/util/dataTable";

import EntryFileMappingRow from "./EntryFileMappingRow.vue";

export default {
  components: {
    EntryFileMappingRow,
  },
  props: {
    entries: Array,
    entryFieldMappings: Object,
    availableFields: Array,
    unitTypes: Array,
  },
  data() {
    return {
      selectedEntries: [],
      editModeEntries: [],
      tableFooterProps: footerProps,
    };
  },
  computed: {
    entriesWithIndex() {
      return this.entries.map((entry, index) => ({
        index: index,
        ...entry,
      }));
    },
    headers() {
      return Object.keys(this.entryFieldMappings).map((x) => ({
        text: this.entryFieldMappings[x],
        value: x,
      }));
    },
    tableHeaders() {
      if (!this.headers?.length) return [];

      const actionHeader = {
        text: "",
        value: "action",
        width: 92,
        align: "center",
        class: "fixed",
      };

      return [...this.headers, actionHeader];
    },
    formattedEntryFields() {
      const languageKeyPrefix =
        "components.integrations.manualFileUpload.fields.";

      const fields = [];

      for (let field of this.availableFields) {
        const languageKey = `${languageKeyPrefix}${field}`;

        fields.push({
          text: this.$te(languageKey) ? this.$t(languageKey) : field,
          value: field,
        });
      }

      return fields;
    },
    entryFieldsWithSkip() {
      const skipText = this.$t(
        "components.integrations.manualFileUpload.stepThree.mapping.skip"
      );
      const unknownText = this.$t(
        "components.integrations.manualFileUpload.stepThree.mapping.unknown"
      );

      const skipField = {
        text: `${skipText} (${unknownText})`,
        value: null,
        unknown: true,
      };

      return [skipField, ...this.formattedEntryFields];
    },
  },
  methods: {
    handleFieldMappingChanged(index, fieldName) {
      const updatedMappings = { ...this.entryFieldMappings };

      updatedMappings[index] = fieldName;

      this.$emit("update:entry-field-mappings", updatedMappings);
    },
    getFieldDisabledStatus(formattedEntryField) {
      return (
        !!formattedEntryField.value &&
        Object.values(this.entryFieldMappings).includes(
          formattedEntryField.value
        )
      );
    },
    isEditMode(tableEntry) {
      const entry = this.entries[tableEntry.index];

      return this.editModeEntries.includes(entry);
    },
    toggleEditMode(tableEntry) {
      const entry = this.entries[tableEntry.index];

      if (this.editModeEntries.includes(entry)) {
        this.editModeEntries = this.editModeEntries.filter((x) => x !== entry);
      } else {
        this.editModeEntries.push(entry);
      }

      this.$emit(
        "update:editing-entry-mappings",
        this.editModeEntries.length > 0
      );
    },
    editEntryField(entry, field, value) {
      this.$emit("update:entry-field", entry.index, field, value);
    },
    deleteEntry(tableEntry) {
      const confirmText = this.$t(
        "components.integrations.manualFileUpload.stepThree.mapping.confirmDelete"
      );

      if (!confirm(confirmText)) return;

      const entry = this.entries[tableEntry.index];

      const filteredEntries = this.entries.filter((x) => x !== entry);

      this.$emit("update:entries", filteredEntries);

      this.selectedEntries = this.selectedEntries.filter(
        (x) => x !== tableEntry
      );
      this.editModeEntries = this.editModeEntries.filter((x) => x !== entry);
    },
    deleteEntryRange(tableEntries) {
      const confirmText = this.$t(
        "components.integrations.manualFileUpload.stepThree.mapping.confirmDeleteRange",
        {
          amount: tableEntries.length,
        }
      );

      if (!confirm(confirmText)) return;

      const indexes = tableEntries.map((x) => x.index);

      const deleteEntries = this.entries.filter((_, index) =>
        indexes.includes(index)
      );

      const filteredEntries = this.entries.filter(
        (x) => !deleteEntries.includes(x)
      );

      this.$emit("update:entries", filteredEntries);

      this.selectedEntries = this.selectedEntries.filter(
        (x) => !tableEntries.includes(x)
      );
      this.editModeEntries = this.editModeEntries.filter(
        (x) => !deleteEntries.includes(x)
      );
    },
  },
};
</script>
