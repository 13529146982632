<template>
  <layout :title="reportTitle" :titleValues="{ dateRange: reportDateRange }">
    <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
      <v-container fluid class="py-4">
        <v-row class="px-2">
          <v-col cols="12" lg="8" xl="6" offset-xl="1" class="pt-0 px-2">
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
            >
              <div class="mb-4 px-5 py-5 fill-height d-flex flex-column">
                <component
                  :is="forms[currentFormIndex]"
                  :reportId="reportId"
                  @form-state-changed="(state) => formStateChanged(state)"
                ></component>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pt-0 px-2">
            <v-card
              light
              color="#fff"
              elevation="0"
              rounded="0"
              max-width="100%"
            >
              <div class="mb-4 px-5 py-5 fill-height d-flex flex-column">
                <h3 class="mb-8 font-weight-regular text-h5">
                  {{ $t("pages.esg.report.navigation.title") }}
                </h3>
                <v-list>
                  <v-list-item
                    v-for="(form, i) in forms"
                    :key="i"
                    :input-value="currentFormIndex === i"
                    @click="handleFormSelection(i)"
                    color="primary"
                  >
                    <v-list-item-content
                      >{{ $t("pages.esg.report.navigation.pages." + form) }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="showUnsavedChangesDialog" persistent max-width="340">
        <v-card>
          <v-card-title class="text-h5">{{
            $t("pages.esg.report.dialog.confirmDiscardData.title")
          }}</v-card-title>
          <v-card-text>{{
            $t("pages.esg.report.dialog.confirmDiscardData.description")
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text tile @click="cancelFormChange()">
              {{ $t("pages.esg.report.dialog.confirmDiscardData.cancel") }}
            </v-btn>
            <v-btn color="primary" text tile @click="confirmDiscardData()">
              {{ $t("pages.esg.report.dialog.confirmDiscardData.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </layout>
</template>

<script>
import FormB1 from "./Forms/FormB1.vue";
import FormB2 from "./Forms/FormB2.vue";
import FormB3 from "./Forms/FormB3.vue";
import FormB4 from "./Forms/FormB4.vue";
import FormB5 from "./Forms/FormB5.vue";
import FormB6 from "./Forms/FormB6.vue";
import FormB7 from "./Forms/FormB7.vue";
import FormB8 from "./Forms/FormB8.vue";
import FormB9 from "./Forms/FormB9.vue";
import FormB10 from "./Forms/FormB10.vue";
import FormB11 from "./Forms/FormB11.vue";
import FormB12 from "./Forms/FormB12.vue";
import Layout from "../../Shared/Layout.vue";
import { formatDate } from "../../util/dateTime";
export default {
  components: {
    FormB1,
    FormB2,
    FormB3,
    FormB4,
    FormB5,
    FormB6,
    FormB7,
    FormB8,
    FormB9,
    FormB10,
    FormB11,
    FormB12,
    Layout,
  },

  props: {
    reportId: String,
    section: String,
    defaultDateFormat: String,
  },
  mounted() {
    const componentName = "form-" + this.section;
    const formIndex = this.forms.findIndex((form) => form === componentName);

    if (formIndex !== -1) {
      this.currentFormIndex = formIndex;
    } else {
      this.currentFormIndex = 0;
    }

    this.fetchReportTitle();
  },
  data() {
    return {
      forms: [
        "form-b1",
        "form-b2",
        "form-b3",
        "form-b4",
        "form-b5",
        "form-b6",
        "form-b7",
        "form-b8",
        "form-b9",
        "form-b10",
        "form-b11",
        "form-b12",
      ],
      hasChanges: false,
      showUnsavedChangesDialog: false,
      currentFormIndex: 0,
      suggestedFormIndex: 0,
      reportTitle: "",
      reportDateRange: {
        from: "",
        to: "",
      },
    };
  },
  methods: {
    formatDate,
    fetchReportTitle() {
      const defaultDateFormat =
        this.$inertia.page.props.auth?.settings.defaultDateFormat;

      fetch(this.route("api.esg-reports.get", this.reportId))
        .then((r) => r.json())
        .then((data) => {
          this.reportTitle = data.name;
          this.reportDateRange = {
            from: this.formatDate(data.startDate, defaultDateFormat),
            to: this.formatDate(data.endDate, defaultDateFormat),
          };
        });
    },
    updateSectionPath(pathSegment) {
      if (!pathSegment) {
        pathSegment = "b1";
      }

      const currentPath = window.location.pathname;

      // Replace the last segment with the mapped path segment
      const newPath = currentPath.replace(/\/[a-zA-Z]+\d+$/, `/${pathSegment}`);

      window.history.replaceState(null, "", newPath);
    },
    formStateChanged(state) {
      this.hasChanges = state;
    },
    handleFormSelection(index) {
      if (!this.hasChanges) {
        this.currentFormIndex = index;
      } else {
        this.suggestedFormIndex = index;
        if (this.suggestedFormIndex !== this.currentFormIndex) {
          this.showUnsavedChangesDialog = true;
        }
      }
    },
    cancelFormChange() {
      this.showUnsavedChangesDialog = false;
    },
    confirmDiscardData() {
      this.currentFormIndex = this.suggestedFormIndex;
      this.showUnsavedChangesDialog = false;
      this.hasChanges = false;
    },
  },
  watch: {
    currentFormIndex(newIndex) {
      const indexToPathSegment = {
        0: "b1",
        1: "b2",
        2: "b3",
        3: "b4",
        4: "b5",
        5: "b6",
        6: "b7",
        7: "b8",
        8: "b9",
        9: "b10",
        10: "b11",
        11: "b12",
      };
      let pathSegment = indexToPathSegment[newIndex];
      this.updateSectionPath(pathSegment);
    },
  },
};
</script>

<style lang="scss" scoped></style>
