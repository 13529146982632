const b1_21a_fieldValues = {
  basicModeOnly: "6E0B28C1-B994-4315-8940-B5F83795DCFC",
  basicAndNarrativePAT: "94D98064-5441-4F98-AD04-687A2457DA9B",
  basicAndBusinessPartners: "E3E3D1F1-4648-4E7A-AAC1-1C2ADC4E2A19",
  basicAndNarrativePATAndBusinessPartner:
    "3F983CCF-026B-4DB5-8CDF-943037C01FB2",
};

const b1_21b_fieldValues = {
  individualBasis: "D72F1493-B5A1-4380-940E-95B995C963DE",
  consolidatedBasis: "05DBC897-3FCA-4EC5-9F46-D06C1D66DBB5",
};

const doesApplyCircularEconomyPrinciplesOptionValues = {
  yes: "E024945D-D4DE-455A-B28F-97759851D683",
  no: "A321CD0F-11AA-478B-9BED-5FD8915EFBF8",
};

export {
  b1_21a_fieldValues,
  b1_21b_fieldValues,
  doesApplyCircularEconomyPrinciplesOptionValues,
};
