var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-5"},[_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"col-12 border mb-3 justify-center d-flex align-center pointer",class:{ 'red-border': _vm.selectedIntegration === _vm.integrations.economic },staticStyle:{"height":"90px"},on:{"click":function($event){return _vm.selectIntegration(_vm.integrations.economic)}}},[_c('EConomicLogo')],1)]),_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"col-12 border mb-3 justify-center d-flex align-center pointer",class:{ 'red-border': _vm.selectedIntegration === _vm.integrations.uniconta },staticStyle:{"height":"90px"},on:{"click":function($event){return _vm.selectIntegration(_vm.integrations.uniconta)}}},[_c('UnicontaLogo')],1)]),_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"col-12 border mb-3 justify-center d-flex align-center pointer",class:{
          'red-border': _vm.selectedIntegration === _vm.integrations.businessCentral,
        },staticStyle:{"height":"90px"},on:{"click":function($event){return _vm.selectIntegration(_vm.integrations.businessCentral)}}},[_c('Dynamic365Logo')],1)]),_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"col-12 border mb-3 justify-center d-flex align-center pointer",class:{
          'red-border': _vm.selectedIntegration === _vm.integrations.manualFileUpload,
        },staticStyle:{"height":"90px"},on:{"click":function($event){return _vm.selectIntegration(_vm.integrations.manualFileUpload)}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-center flex-col"},[_c('v-icon',{attrs:{"x-large":"","color":"black"}},[_vm._v("mdi-file-document-outline")]),_c('span',{staticClass:"text-h6 ml-4 d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.stepThree.manualFileUpload"))+" ")])],1)],1)],1)]),(_vm.showContinue)?_c('v-row',{attrs:{"align":"center"}},[_c('div',{staticClass:"col-12 border justify-center d-flex align-center flex-column pointer",class:{ 'red-border': _vm.selectedIntegration === _vm.integrations.other },style:({
          height: _vm.selectedIntegration === _vm.integrations.other ? '90px' : '90px',
        }),on:{"click":function($event){return _vm.selectIntegration(_vm.integrations.other)}}},[_c('div',{staticClass:"py-2"},[_c('span',{staticClass:"pointer",style:({
              color:
                _vm.selectedIntegration === _vm.integrations.other
                  ? '#F25D3B'
                  : 'inherit',
            }),attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("components.onboarding.stepThree.systemNotHere"))+" ")])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }