<template>
  <v-row>
    <v-col cols="12">
      <v-radio-group
        column
        v-model="internalValue"
        :error-messages="errorMessage"
      >
        <template v-slot:label>
          <div class="grey--text">
            {{ title }}
          </div>
          <div class="black--text mb-2">
            {{ description }}
          </div>
        </template>

        <v-radio
          class="mb-3"
          v-for="(option, index) in options"
          :key="index"
          :value="option[valueKey]"
        >
          <template #label>
            <div class="body-2 black--text">
              <strong>{{ option.name }}</strong>
              {{ option.description }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    options: Array,
    value: String,
    valueKey: {
      default: "key",
      type: String,
    },
    title: String,
    description: String,
    errorMessage: String,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
