<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b11.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText class="grey--text body-2 mb-0">
        {{ $t("pages.esg.report.b11.37.questionNumber") }}
      </ESGText>
      <ESGText>
        <i18n path="pages.esg.report.b11.37.questionHeader" tag="span">
          <span class="font-weight-bold font-italic">
            {{ $t("pages.esg.report.b11.37.valueChain") }}</span
          >
          <span class="font-weight-bold font-italic">
            {{ $t("pages.esg.report.b11.37.affectedCommunities") }}</span
          >
          <span class="font-weight-bold font-italic">
            {{ $t("pages.esg.report.b11.37.consumers") }}</span
          >
        </i18n>
      </ESGText>
      <ESGTextArea
        v-model="description"
        :counter="descriptionCharLimit"
        :errorMessage="formErrors.description"
        :fieldLabel="$t('pages.esg.report.b11.37.fieldLabel')"
      ></ESGTextArea>
      <ESGTableRowEditor
        :description="$t('pages.esg.report.b11.other.questionHeader')"
        v-model="negativeImpacts"
        :columns="[
          {
            name: $t('pages.esg.report.b11.other.columns.impact.name'),
            placeholder: $t(
              'pages.esg.report.b11.other.columns.impact.placeholder'
            ),
            key: 'impact',
          },
          {
            name: $t('pages.esg.report.b11.other.columns.where.name'),
            placeholder: $t(
              'pages.esg.report.b11.other.columns.where.placeholder'
            ),
            key: 'where',
          },
          {
            name: $t('pages.esg.report.b11.other.columns.whoWereAffacted.name'),
            placeholder: $t(
              'pages.esg.report.b11.other.columns.whoWereAffacted.placeholder'
            ),
            key: 'whoWereAffacted',
          },
        ]"
      ></ESGTableRowEditor>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import ESGTextArea from "../../../Components/ESGFormFields/ESGTextArea.vue";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";
import { serialize } from "object-to-formdata";

export default {
  components: {
    ESGText,
    ESGTextArea,
    ESGConfirmButton,
    ESGTableRowEditor,
  },
  props: {
    reportId: String,
  },
  data() {
    return {
      description: "",
      descriptionCharLimit: 2500,
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
      negativeImpacts: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b11",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.description = data.question37Text;
          if (data.question37Rows !== null && data.question37Rows.length > 0) {
            this.negativeImpacts = data.question37Rows.map((sub) => {
              return { editable: false, ...sub };
            });
          }
        })
        .finally(() => {
          this.$emit("form-state-changed", false);
          this.loadingData = false;
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        description: this.description,
        negativeImpacts: this.negativeImpacts,
      });

      if (Object.keys(errors).length > 0) {
        this.formErrors = errors;
        this.submittingData = false;
        return;
      }

      // Send data
      const formData = {
        question37Text: this.description,
        question37Rows: this.negativeImpacts,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b11", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
 
    },

    validateFormData(formData) {
      const errors = {};

      if (formData.description.length === 0) {
        errors.description = this.$t(
          "pages.esg.report.validation.pleaseFillField"
        );
      } else if (formData.description.length > this.descriptionCharLimit) {
        errors.description = this.$t(
          "pages.esg.report.validation.tooManyCharacters"
        );
      }

      return errors;
    },
  },
  watch: {
    description: {
      deep: true,
      handler(newValue) {
        if (newValue.length < this.descriptionCharLimit) {
          this.formErrors.description = null;
          this.changesSaved = false;
        }
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped></style>
