<template>
  <div
    @click="toggleSwitch"
    :class="{
      'outer-switch': true,
      'outer-switch-on': value,
      'outer-switch-off': !value,
    }"
  >
    <div
      :class="{
        'inner-switch': true,
        'inner-switch-left': !value,
        'inner-switch-right': value,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  methods: {
    toggleSwitch() {
      this.$emit("change", !this.value);
    },
  },
};
</script>

<style>
.outer-switch {
  width: 41px;
  height: 24px;
  border-radius: 30px;
  background: #aeaeae;
  cursor: pointer;
  position: relative;
}

.outer-switch-off {
  background: #aeaeae;
  transition: background 0.4s;
}

.outer-switch-on {
  background: #f25d3b;
  transition: background 0.4s;
}

.inner-switch {
  height: 18px;
  width: 18px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.inner-switch-left {
  top: 3px;
  left: 3px;
  transition: left 0.4s;
}

.inner-switch-right {
  top: 3px;
  left: 20px;
  transition: left 0.4s;
}
</style>
