<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b4.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGTableRowEditor
        :title="$t('pages.esg.report.b4.26.label')"
        :description="$t('pages.esg.report.b4.26.description')"
        v-model="pollutants"
        :errorMessage="formErrors.pollutants"
        :errorsOnLines="formErrors.pollutantsLines"
        :columns="[
          {
            name: $t('pages.esg.report.b4.26.columns.pollutants_type'),
            key: 'type',
          },
          {
            name: $t('pages.esg.report.b4.26.columns.pollutants_amount'),
            key: 'amount',
            inputUnit: 'kg',
          },
          {
            name: $t('pages.esg.report.b4.26.columns.pollutants_where'),
            key: 'where',
          },
          {
            name: $t('pages.esg.report.b4.26.columns.pollutants_url'),
            key: 'url',
          },
        ]"
      ></ESGTableRowEditor>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGTableRowEditor from "../../../Components/ESGFormFields/ESGTableRowEditor.vue";
import { serialize } from "object-to-formdata";

export default {
  components: { ESGTableRowEditor, ESGConfirmButton },
  props: {
    reportId: String,
  },
  data() {
    return {
      pollutants: Array(3)
        .fill(null)
        .map(() => {
          return { editable: true };
        }),
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b4",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.question26Rows !== null && data.question26Rows.length > 0) {
            this.pollutants = data.question26Rows.map((sub) => {
              return { editable: false, ...sub };
            });
          }
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        pollutants: this.pollutants,
      });

      if (Object.keys(errors).length > 0) {
        this.submittingData = false;
        this.formErrors = errors;
        return;
      }

      const formData = {
        question26Rows: this.pollutants,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b4", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateFormData(formData) {
      const errors = {};

      const unconfirmedChanges =
        formData.pollutants
          // Add index
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter(
            (sub) =>
              sub.editable && (sub.type || sub.amount || sub.where || sub.url)
          ) ?? [];

      if (unconfirmedChanges.length > 0) {
        errors.pollutants = this.$t(
          "pages.esg.report.validation.youHaveUnconfirmedChanges"
        );
        errors.pollutantsLines = unconfirmedChanges.map((sub) => sub.index);

        return errors;
      }

      // Format
      const wrongFormat =
        formData.pollutants
          // Add index
          .map((sub, index) => {
            return { ...sub, index };
          })
          .filter((sub) => isNaN(sub.amount)) ?? [];

      if (wrongFormat.length > 0) {
        errors.pollutants = this.$t("pages.esg.report.validation.wrongFormat");
        errors.pollutantsLines = wrongFormat.map((sub) => sub.index);
      }

      return errors;
    },
  },
  watch: {
    pollutants: {
      deep: true,
      handler() {
        this.formErrors = {};
        this.changesSaved = false;
        this.$emit("form-state-changed", true);
      },
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped>
 
</style>
