<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-card
      elevation="0"
      min-height="17.625rem"
      class="d-flex flex-column ma-4"
    >
      <v-data-table
        class="report-table"
        :headers="headers"
        :header-props="{ 'sort-icon': 'mdi-unfold-more-horizontal' }"
        :items="agreementList"
        hide-default-footer
      >
        <template #item.agreement_name="{ item }">
          <a :href="item.agreement_url">
            <strong>{{ item.agreement_name }}</strong>
          </a>
        </template>
        <template #item.version="{ item }">
          <span>
            <strong>{{ item.version }}</strong>
          </span>
        </template>
        <template #item.consent_given="{ item }">
          <span v-if="item.consent_given_date">
            <strong>{{ item.consent_given_date }}</strong> by
            <strong>{{ item.consent_given_by }}</strong>
          </span>
        </template>
        <template #item.consent_withdrawn="{ item }">
          <span v-if="item.consent_withdrawn_date">
            <strong>{{ item.consent_withdrawn_date }}</strong> by
            <strong>{{ item.consent_withdrawn_by }}</strong>
          </span>
        </template>
        <template #item.consent="{ item }">
          <toggle-button
            :value="item.consent_status"
            @change="(val) => (item.consent_status = val)"
          ></toggle-button>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import ToggleButton from "../../Components/Buttons/ToggleButton.vue";
export default {
  components: { ToggleButton },
  layout: appLayout({ title: "pages.agreements.title" }),
  data() {
    return {
      headers: [
        {
          text: this.$t("pages.agreements.list.agreement_name"),
          align: "start",
          value: "agreement_name",
        },
        {
          text: this.$t("pages.agreements.list.version"),
          value: "version",
        },
        {
          text: this.$t("pages.agreements.list.consentGiven"),
          value: "consent_given",
        },
        {
          text: this.$t("pages.agreements.list.consentWithdrawn"),
          value: "consent_withdrawn",
        },
        {
          text: this.$t("pages.agreements.list.consent"),
          value: "consent",
        },
      ],
      agreementList: [
        {
          agreement_name: "Verarca Terms & Conditions",
          agreement_url: "#terms-and-conditions",
          version: "2034-03-12",
          consent_given_date: "2023-06-06",
          consent_given_by: "John Michell",
          consent_withdrawn_date: null,
          consent_withdrawn_by: null,
          consent_status: true,
        },
        {
          agreement_name: "Cookies & Privacy Policy",
          agreement_url: "#cookies-and-privacy",
          version: "2034-03-12",
          consent_given_date: "2023-06-06",
          consent_given_by: "John Michell",
          consent_withdrawn_date: null,
          consent_withdrawn_by: null,
          consent_status: true,
        },
        /*{
          agreement_name: "Jyske Bank ESG conditions",
          agreement_url: "#jbec",
          version: "2034-03-12",
          consent_given_date: "2023-06-06",
          consent_given_by: "John Michell",
          consent_withdrawn_date: null,
          consent_withdrawn_by: null,
          consent_status: true,
        },
        {
          agreement_name: "Jyske Bank financial conditions",
          agreement_url: "#jbfc",
          version: "2034-03-12",
          consent_given_date: "2023-01-02",
          consent_given_by: "John Michell",
          consent_withdrawn_date: "2024-04-05",
          consent_withdrawn_by: "John Michell 2",
          consent_status: false,
        },*/
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
