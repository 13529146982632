<template>
  <div class="flex-grow-1 d-flex justify-center" style="padding-top: 126px">
    <div class="d-flex justify-center">
      <div class="px-0" fluid style="width: 1313px">
        <div class="mb-4">
          <div class="page-title">
            {{
              $t(
                "pages.reports.climateReport.topEmissionFactorCategories.title"
              )
            }}
          </div>
          <div class="page-subtitle">
            {{
              $t(
                "pages.reports.climateReport.topEmissionFactorCategories.subtitle"
              )
            }}
          </div>
        </div>
        <v-card
          light
          color="#fff"
          elevation="0"
          rounded="0"
          max-width="100%"
          class="flex-grow-1"
        >
          <div class="px-5 pb-5 pt-2">
            <v-simple-table class="statistics-table">
              <template v-slot:default>
                <thead>
                  <tr class="table-header">
                    <th width="auto" class="text-left">
                      {{
                        $t(
                          "components.reportTemplates.reportTopEmissionFactorCategories.topEmissionFactorCategories"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportTopEmissionFactorCategories.factorCategory1"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportTopEmissionFactorCategories.factorCategory2"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportTopEmissionFactorCategories.factorCategory3"
                        )
                      }}
                    </th>
                    <th width="15%" class="text-right">
                      {{
                        $t(
                          "components.reportTemplates.reportTopEmissionFactorCategories.factorCategory4"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      emissionFactorCategory, index
                    ) in top18EmissionFactorCategories"
                    :key="index"
                  >
                    <td>
                      {{ emissionFactorCategory.category }}
                    </td>
                    <td class="text-right">
                      {{ Math.round(emissionFactorCategory.quantity) }}
                    </td>
                    <td class="text-right">
                      {{
                        formatKgToTonEmissions(emissionFactorCategory.emissions)
                          .value
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(emissionFactorCategory.percentEmissions)
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        formatCurrency(emissionFactorCategory.percentQuantity)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Formatters from "../../mixins/Formatters.vue";

export default {
  mixins: [Formatters],
  props: {
    emissionFactorsAppendixPageData: Array,
  },
  computed: {
    top18EmissionFactorCategories() {
      if (!this.emissionFactorsAppendixPageData) {
        return [];
      }

      // Top Emission Factors Sources
      const topEmissionFactorsSources = this.emissionFactorsAppendixPageData
        //.filter((factor) => factor && factor.source !== null)
        .reduce((acc, factor) => {
          const categoryName =
            factor.category !== null
              ? factor.category
              : this.$t(
                  "pages.reports.climateReport.topEmissionFactorCategories.noEmissionFactorCategoryName"
                );

          const index = acc.findIndex((item) => item.category === categoryName);
          if (index !== -1) {
            acc[index].emissions += parseFloat(factor.emissions);
            acc[index].quantity += parseFloat(factor.numberOfPurchases);
            acc[index].percentEmissions += parseFloat(factor.percentEmissions);
            acc[index].percentQuantity += parseFloat(
              factor.percentNumberOfPurchases
            );
          } else {
            acc.push({
              category: categoryName,
              emissions: parseFloat(factor.emissions),
              quantity: parseFloat(factor.numberOfPurchases),
              percentEmissions: parseFloat(factor.percentEmissions),
              percentQuantity: parseFloat(factor.percentNumberOfPurchases),
            });
          }
          return acc;
        }, [])
        .sort((a, b) => b.emissions - a.emissions);

      // Select top 5 emitting factors
      const top5EmissionFactorsSources = topEmissionFactorsSources.slice(0, 18);

      // Aggregate the rest into "Other" category
      const otherCategory = {
        category: this.$t("common.rootCauses.Other"),
        emissions: topEmissionFactorsSources
          .slice(5)
          .reduce((acc, item) => acc + parseFloat(item.emissions), 0),
        quantity: topEmissionFactorsSources
          .slice(5)
          .reduce((acc, item) => acc + parseFloat(item.quantity), 0),
        percentEmissions: topEmissionFactorsSources
          .slice(5)
          .reduce((acc, item) => acc + parseFloat(item.percentEmissions), 0),
        percentQuantity: topEmissionFactorsSources
          .slice(5)
          .reduce((acc, item) => acc + parseFloat(item.percentQuantity), 0),
      };

      // Add "Other" category to top 5
      top5EmissionFactorsSources.push(otherCategory);

      return top5EmissionFactorsSources;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  margin-bottom: 0;
  color: #707070;
}

.card-value {
  font-size: 25px;
}

.page-title {
  font-size: 35px;
}
.page-subtitle {
  font-size: 20px;
  color: #707070;
}

.statistics-table {
  thead {
    .table-header {
      th {
        font-weight: 200;
        color: #707070;
        font-size: 14px;
        padding-bottom: 10px;
        &:first-child {
          font-size: 18px;
        }
      }
    }
  }
  tbody {
    tr {
      background: #fbfbfb;

      td {
        border-bottom: thin solid #fff !important;

        height: 35px !important;
      }
    }
    .sub-header {
      background: #f5f5f5;
    }
  }
}
.footnote {
  color: #707070;
  strong {
    font-weight: 600;
  }
}
</style>
