<template>
  <div>
    <h3 class="mb-8 font-weight-regular text-h5 font-weight-medium">
      {{ $t("pages.esg.report.b3.title") }}
    </h3>
    <v-container fluid v-if="loadingData">
      <v-progress-circular indeterminate color="#f25d3b"></v-progress-circular>
    </v-container>
    <v-container fluid v-else>
      <ESGText>{{ $t("pages.esg.report.b3.text1") }}</ESGText>
      <ESGText>{{ $t("pages.esg.report.b3.text2") }}</ESGText>
      <ESGTextField
        class="mb-10"
        :questionNumber="$t('pages.esg.report.b3.24a.label')"
        :questionText="$t('pages.esg.report.b3.24a.description')"
        :inputUnit="$t('pages.esg.report.b3.24a.inputUnit')"
        v-model="b3_24a"
        :errorMessage="formErrors.b3_24a"
      ></ESGTextField>
      <ESGTextField
        :questionNumber="$t('pages.esg.report.b3.24b.label')"
        :questionText="$t('pages.esg.report.b3.24b.renewable.description')"
        :inputUnit="$t('pages.esg.report.b3.24b.renewable.inputUnit')"
        v-model="b3_24b_renewable"
        :errorMessage="formErrors.b3_24b_renewable"
      ></ESGTextField>
      <ESGTextField
        class="mb-16"
        :questionText="$t('pages.esg.report.b3.24b.nonRenewable.description')"
        :inputUnit="$t('pages.esg.report.b3.24b.nonRenewable.inputUnit')"
        v-model="b3_24b_nonRenewable"
        :errorMessage="formErrors.b3_24b_nonRenewable"
      ></ESGTextField>
      <ESGText>{{ $t("pages.esg.report.b3.text3") }}</ESGText>
      <ESGTextField
        class="mb-10"
        :questionNumber="$t('pages.esg.report.b3.25a.label')"
        :questionText="$t('pages.esg.report.b3.25a.description')"
        :inputUnit="$t('pages.esg.report.b3.25a.inputUnit')"
        v-model="b3_25a"
        :errorMessage="formErrors.b3_25a"
      ></ESGTextField>
      <ESGTextField
        class="mb-10"
        :questionNumber="$t('pages.esg.report.b3.25b.label')"
        :questionText="$t('pages.esg.report.b3.25b.description')"
        :inputUnit="$t('pages.esg.report.b3.25b.inputUnit')"
        v-model="b3_25b"
        :errorMessage="formErrors.b3_25b"
      ></ESGTextField>
      <ESGConfirmButton
        @click="save()"
        :loading="submittingData"
        :changesSaved="changesSaved"
      ></ESGConfirmButton>
    </v-container>
  </div>
</template>

<script>
import ESGConfirmButton from "../../../Components/ESGFormFields/ESGConfirmButton.vue";
import ESGText from "../../../Components/ESGFormFields/ESGText.vue";
import ESGTextField from "../../../Components/ESGFormFields/ESGTextField.vue";
import { serialize } from "object-to-formdata";

export default {
  components: { ESGTextField, ESGText, ESGConfirmButton },
  props: {
    reportId: String,
  },
  data() {
    return {
      b3_24a: "",
      b3_24b_renewable: "",
      b3_24b_nonRenewable: "",
      b3_25a: "",
      b3_25b: "",
      formErrors: {},
      loadingData: false,
      submittingData: false,
      changesSaved: false,
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    async getFormData() {
      this.loadingData = true;
      fetch(
        this.route("api.esg-reports.sections.get", {
          id: this.reportId,
          section: "b3",
        }),
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.b3_24a = data.question24aValue;
          this.b3_24b_renewable = data.question24bRenewableValue;
          this.b3_24b_nonRenewable = data.question24bNonRenewableValue;
          this.b3_25a = data.question25aValue;
          this.b3_25b = data.question25bValue;
        })
        .finally(() => {
          this.loadingData = false;
          this.$emit("form-state-changed", false);
        });
    },
    save() {
      this.submittingData = true;

      // Validate form
      const errors = this.validateFormData({
        b3_24a: this.b3_24a,
        b3_24b_renewable: this.b3_24b_renewable,
        b3_24b_nonRenewable: this.b3_24b_nonRenewable,
        b3_25a: this.b3_25a,
        b3_25b: this.b3_25b,
      });

      if (Object.keys(errors).length > 0) {
        this.submittingData = false;
        this.formErrors = errors;
        return;
      }

      const formData = {
        question24aValue: this.b3_24a,
        question24bRenewableValue: this.b3_24b_renewable,
        question24bNonRenewableValue: this.b3_24b_nonRenewable,
        question25aValue: this.b3_25a,
        question25bValue: this.b3_25b,
      };

      // Send data
      fetch(this.route("api.esg-reports.sections.b3", { id: this.reportId }), {
        method: "PUT",
        body: serialize(formData, {
          noFilesWithArrayNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          this.changesSaved = true;
        })
        .finally(() => {
          this.submittingData = false;
        });
    },
    validateFormData(formData) {
      const errors = {};

      Object.keys(formData).forEach((key) => {
        /*if (formData[key] === "") {
          errors[key] = this.$t("pages.esg.report.validation.pleaseFillField");
        }*/
        if (isNaN(formData[key])) {
          errors[key] = this.$t(
            "pages.esg.report.validation.valueMustBeNumber"
          );
        }
      });

      return errors;
    },
  },
  watch: {
    b3_24a() {
      this.formErrors.b3_24a = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b3_24b_renewable() {
      this.formErrors.b3_24b_renewable = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b3_24b_nonRenewable() {
      this.formErrors.b3_24b_nonRenewable = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b3_25a() {
      this.formErrors.b3_25a = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    b3_25b() {
      this.formErrors.b3_25b = null;
      this.changesSaved = false;
      this.$emit("form-state-changed", true);
    },
    changesSaved(to) {
      this.$emit("form-state-changed", !to);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
