<template>
  <div class="page-container fill-height grow d-flex flex-column flex-nowrap">
    <v-container
      fluid
      class="py-0"
      :class="showOverlay ? 'connect-blur' : null"
      style="margin-top: -66px"
    >
      <onboarding-modal
        v-model="onboardingDialog"
        ref="onboardingModalComponent"
        :settings="settings"
        :fiscal-years="fiscalYears"
        :depreciation-methods="depreciationMethods"
        :depreciation-starts="depreciationStarts"
        :currencies="currencies"
        :countries="countries"
        :integrationId="integrationId"
        :serverIntegrationError="integrationError"
        :companyRegNo="companyRegNo"
        :companyName="companyName"
        :companyEmail="companyEmail"
        v-show="onboardingDialog"
        :selected-step.sync="selectedOnboardingStep"
        @show-warning="handleShowWarning"
        @show-api-fields="handleShowApiFields"
        @show-integration="handleShowIntegration"
        @close-all="handleCloseAllPopups"
        @step-changed="selectedOnboardingStep = $event"
        @onAccountMappingSuccess="handleShowIntegration"
        @skip-mapping="handleSkipMapping"
        @open:manual-file-upload-modal="changeToManualFileUploadFlow"
        :integrationInstalled="integrationInstalled"
      />
      <manual-file-upload-modal
        v-model="manualFileUploadModal"
        v-show="manualFileUploadModal"
        @completed-setup="handleCloseAllPopups"
      />
      <onboarding-warning
        v-model="onboardingWarning"
        v-if="onboardingWarning"
        @continue-onboarding="handleContinueOnboarding"
        @close-all="handleCloseAllPopups"
      />
      <api-fields-dialog
        v-model="apiFieldsDialog"
        v-show="apiFieldsDialog"
        @continue-onboarding="handleContinueOnboarding"
        @submit-api-fields="handleSubmitApiFields"
      />
      <integration-dialog
        v-model="integrationDialog"
        @finish-integration="handleFinishIntegration"
        :integrationId="integrationId"
      />
      <payment-dialog
        v-model="paymentDialog"
        :publishableKey="publishableKey"
        :openedFromSettings="false"
        :paymentReturnUrl="paymentReturnUrl"
        :paymentTierIds="paymentTierIds"
        :redirect-to-assets="true"
        :dateRange="dateRange"
        :discountPercentage="discountPercentage"
      />
      <no-mapping-integration-success
        v-model="showSkipMappingModal"
        @confirm-skip="handleFinishIntegration"
      />
      <v-row class="px-2">
        <asset-card-row-default
          context="dashboard"
          :assetCardData="assetCardData" 
        >
          <template #row-end>
            <v-col cols="12" md="6" class="px-2 py-4 d-flex">
              <PieChartCard
                :labels="co2CategoryLabels"
                :colors="['#266663', '#6493BE', '#E35E3E', '#B7B7B7']"
                :dataSeries="getScopes"
                :unitLabel="$t('common.units.tCO2e')"
                :title="$t('components.cards.co2Categories.title')"
                class="flex-grow-1 fill-height"
                :class="showOverlay ? 'connect-color-overlay' : null"
              ></PieChartCard>
            </v-col>
          </template>
        </asset-card-row-default>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" lg="6" class="pt-0 px-2">
          <LineChartCard
            v-if="
              emissionsPerMonthCurrentYear.length > 0 &&
              emissionsPerMonthLastYear.length > 0
            "
            class="mb-4"
            :title="$t('components.cards.co2Emissions.title')"
            :series="adjustedEmissionsPerMonthCurrentYear"
            :compare-series="adjustedEmissionsPerMonthLastYear"
            :unit="
              emissionsPerMonthOver1000kg
                ? this.$t('common.units.tCO2e')
                : this.$t('common.units.kgCO2e')
            "
            :x-axis-categories="months1YearToCurrent"
            :seriesLabel="$t('components.cards.co2Emissions.labels.thisYear')"
            :compareSeriesLabel="
              $t('components.cards.co2Emissions.labels.lastYear')
            "
            :maxVal="
              Math.ceil(
                Math.max(
                  ...[
                    ...adjustedEmissionsPerMonthCurrentYear,
                    ...adjustedEmissionsPerMonthLastYear,
                  ]
                )
              )
            "
            :class="showOverlay ? 'connect-color-overlay' : null"
          ></LineChartCard>
          <v-card
            v-else
            light
            color="#fff"
            elevation="0"
            rounded="0"
            max-width="100%"
            :class="showOverlay ? 'connect-color-overlay' : null"
          >
            <div
              class="mb-4 px-5 py-5 fill-height d-flex flex-column"
              style="height: 550px"
            >
              <h3 class="mb-8 font-weight-regular text-h5">
                {{ $t("components.cards.co2Emissions.title") }}
              </h3>
              <div class="flex-grow-1 h-10 custom-skeleton"></div>
            </div>
          </v-card>
          <bar-chart-card
            v-if="co2MethodsByMonths.length > 0"
            :title="$t('components.cards.emissionsByMethod.title')"
            class="mb-4"
            :series="co2MethodsByMonths"
            :x-axis-categories="months1YearToCurrent"
            :unit="$t('common.units.kgCO2e')"
            :maxVal="
              Math.ceil(
                Math.max(
                  ...[
                    ...co2MethodsByMonths[0]?.data,
                    ...co2MethodsByMonths[1]?.data,
                    ...co2MethodsByMonths[2]?.data,
                  ]
                )
              )
            "
            :class="showOverlay ? 'connect-color-overlay' : null"
          ></bar-chart-card>
          <v-card
            v-else
            light
            color="#fff"
            elevation="0"
            rounded="0"
            max-width="100%"
            :class="showOverlay ? 'connect-color-overlay' : null"
          >
            <div
              class="mb-4 px-5 py-5 fill-height d-flex flex-column"
              style="height: 550px"
            >
              <h3 class="mb-8 font-weight-regular text-h5">
                {{ $t("pages.dashboard.emissionOverviewByCalculationMethod") }}
              </h3>
              <div class="flex-grow-1 h-10 custom-skeleton"></div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6" class="pt-0 px-2">
          <v-card elevation="0" class="simple-table-card">
            <div
              class="px-5 py-5 fill-height d-flex flex-column"
              :class="showOverlay ? 'connect-color-overlay' : null"
            >
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center pb-8">
                  <h3
                    v-if="top20ListMode == 'purchase'"
                    class="font-weight-regular text-h5 flex-grow-1 pr-4"
                  >
                    {{ $t("components.cards.top20Purchases.title") }}
                  </h3>
                  <h3
                    v-else-if="top20ListMode == 'supplier'"
                    class="font-weight-regular text-h5 flex-grow-1 pr-4"
                  >
                    {{ $t("components.cards.top20Suppliers.title") }}
                  </h3>
                  <h3
                    v-else-if="top20ListMode == 'organization'"
                    class="font-weight-regular text-h5 flex-grow-1 pr-4"
                  >
                    {{ $t("components.cards.top20Organizations.title") }}
                  </h3>
                  <h3
                    v-else-if="top20ListMode == 'ghg-categories'"
                    class="font-weight-regular text-h5 flex-grow-1 pr-4"
                  >
                    {{ $t("components.cards.ghgCategories.title") }}
                  </h3>
                  <h3
                    v-else-if="top20ListMode == 'emissions-categories'"
                    class="font-weight-regular text-h5 flex-grow-1 pr-4"
                  >
                    {{ $t("components.cards.top20Co2Categories.title") }}
                  </h3>
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        light
                        plain
                        small
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        class="table-select-btn align-self-start px-0"
                        ><v-icon>mdi-dots-vertical</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item @click="top20ListMode = 'purchase'">
                        <v-list-item-title>{{
                          $t("components.cards.top20Purchases.buttonText")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="top20ListMode = 'supplier'">
                        <v-list-item-title>{{
                          $t("components.cards.top20Suppliers.buttonText")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="isOrganizationGroup"
                        @click="top20ListMode = 'organization'"
                      >
                        {{
                          $t("components.cards.top20Organizations.buttonText")
                        }}
                      </v-list-item>
                      <v-list-item @click="top20ListMode = 'ghg-categories'">
                        <v-list-item-title>{{
                          $t("components.cards.ghgCategories.buttonText")
                        }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="top20ListMode = 'emissions-categories'"
                      >
                        <v-list-item-title>{{
                          $t("components.cards.top20Co2Categories.buttonText")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <v-data-table
                  v-show="top20ListMode == 'supplier'"
                  :headers="emissionsBySupplierHeaders"
                  :items="mappedSuppliers"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                  <template #item.name="{ item }">
                    {{
                      item.name === "no_supplier"
                        ? $t("components.cards.top20Suppliers.noSupplier")
                        : item.name
                    }}
                  </template>
                  <template #item.emissions="{ item }">
                    <emission-percent-bar
                      :emissions="item.emissions"
                      :formattedPercentage="
                        formatPercentage(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                      :percentValue="
                        percentageValue(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                    ></emission-percent-bar>
                  </template>
                  <template #item.numberOfPurchases="{ item }">
                    {{ item.numberOfPurchases }}
                  </template>
                </v-data-table>
                <v-data-table
                  v-if="!isOrganizationGroup"
                  v-show="top20ListMode == 'purchase'"
                  :headers="emissionsBypurchaseHeader"
                  :items="mappedAssets"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                  <template
                    #item.8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2.searchString="{
                      item,
                    }"
                  >
                    {{
                      item["8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2"]?.stringValue
                    }}
                    {{
                      formatCurrency(
                        item["8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2"]
                          ?.decimalValue
                      )
                    }}
                  </template>
                </v-data-table>
                <v-data-table
                  v-else
                  v-show="top20ListMode == 'purchase'"
                  :headers="highestEmittingAssetsOrganizationGroupHeader"
                  :items="mappedHighestEmittingAssetsOrganizationGroup"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                </v-data-table>
                <v-data-table
                  v-show="top20ListMode == 'organization'"
                  :headers="emissionsByOrganizationsHeaders"
                  :items="mappedOrganizations"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                  <template #item.emissions="{ item }">
                    <emission-percent-bar
                      :emissions="item.emissions"
                      :formattedPercentage="
                        formatPercentage(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                      :percentValue="
                        percentageValue(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                    ></emission-percent-bar>
                  </template>
                </v-data-table>
                <v-data-table
                  v-show="top20ListMode == 'ghg-categories'"
                  :headers="emissionsByGhgCategoriesHeader"
                  :items="mappedGhgCategories"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                  <template #item.emissions="{ item }">
                    <emission-percent-bar
                      :emissions="item.emissions"
                      :formattedPercentage="
                        formatPercentage(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                      :percentValue="
                        percentageValue(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                    ></emission-percent-bar>
                  </template>
                </v-data-table>
                <v-data-table
                  v-show="top20ListMode == 'emissions-categories'"
                  :headers="emissionsByEmissionsCategoriesHeader"
                  :items="mappedEmissionCategories"
                  :items-per-page="20"
                  hide-default-footer
                  disable-sort
                  class="data-table"
                >
                  <template #item.category="{ item }">
                    {{
                      item.category === "no_category"
                        ? $t("components.cards.top20Co2Categories.noCategory")
                        : item.category
                    }}
                  </template>
                  <template #item.emissions="{ item }">
                    <emission-percent-bar
                      :emissions="item.emissions"
                      :formattedPercentage="
                        formatPercentage(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                      :percentValue="
                        percentageValue(
                          item.rawValue,
                          item.totalEmissionsRawValue
                        )
                      "
                    ></emission-percent-bar>
                  </template>
                </v-data-table>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        tile
        elevation="0"
        class="svg-button"
        :href="openPresentation"
        target="_blank"
      >
        <stretch-icon></stretch-icon>
      </v-btn>
    </v-container>
    <div v-if="showOverlay" class="connect-overlay">
      <div class="connect-content">
        <div
          class="no-pointer download-icon d-flex align-center justify-center mb-6"
        >
          <DownloadFolder></DownloadFolder>
        </div>
        <p class="no-pointer title text-h6 black--text font-weight-regular">
          {{ $t("pages.dashboard.noDataAvailable") }}
        </p>
        <p
          v-if="!isOrganizationGroup && !settings.skippedOnboarding"
          class="description grey--text text-center mb-6"
        >
          {{ $t("pages.dashboard.getStartedDescription") }}
        </p>
        <v-btn
          v-if="!isOrganizationGroup"
          class="text-none ml-0 mb-4 mb-md-0 px-6"
          large
          color="#F25D3B"
          dark
          elevation="0"
          tile
          x-large
          @click="onboardingDialog = true"
        >
          {{ $t("pages.dashboard.getStarted") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { appLayout } from "@/util/layout";
import PieChartCard from "../../Components/Cards/PieChartCard.vue";
import LineChartCard from "../../Components/Cards/LineChartCard.vue";

import OnboardingModal from "../../Components/Onboarding/OnboardingModal.vue";
import OnboardingWarning from "../../Components/Onboarding/OnboardingWarning.vue";
import ApiFieldsDialog from "../../Components/Onboarding/ApiFieldsDialog.vue";
import IntegrationDialog from "../../Components/Onboarding/IntegrationDialog.vue";
import PaymentDialog from "../../Components/Onboarding/PaymentDialog.vue";
import ManualFileUploadModal from "../../Components/Integration/ManualFileUploadModal.vue";
import BarChartCard from "../../Components/Cards/BarChartCard.vue";
import {
  currencyFormatter,
  integerNumberFormatter,
} from "../../util/formatters";

import GlobalDateRange from "../../mixins/GlobalDateRange";

import DownloadFolder from "../../assets/svg/folder-download.svg";
import StretchIcon from "../../assets/svg/stretch.svg";
import dashboardDummyData from "../../util/dashboardDummyData";
import NoMappingIntegrationSuccess from "../../Components/Onboarding/NoMappingIntegrationSuccess.vue";
import EmissionPercentBar from "../../Components/Outputs/EmissionPercentBar.vue";
import Formatters from "../../mixins/Formatters.vue";
import AssetCardRowDefault from "../../Components/StatCardRows/AssetCardRowDefault.vue";

const _scope1 = "scope1";
const _scope2 = "scope2";
const _scope3 = "scope3";

export default {
  mixins: [GlobalDateRange, Formatters],
  components: {
    PieChartCard,
    LineChartCard,
    OnboardingModal,
    OnboardingWarning,
    ApiFieldsDialog,
    IntegrationDialog,
    PaymentDialog,
    // DonateCoin,
    BarChartCard,
    DownloadFolder,
    StretchIcon,
    NoMappingIntegrationSuccess,
    EmissionPercentBar,
    ManualFileUploadModal,
    AssetCardRowDefault,
  },
  layout: appLayout({ title: "pages.dashboard.title", pushContent: true }),
  props: {
    fiscalYears: Array,
    depreciationMethods: Array,
    depreciationStarts: Array,
    showOnboardingDialog: Boolean,
    integrationInstalled: Boolean,
    integrationError: String,
    integrationId: String,
    currencies: Array,
    countries: Array,
    hasAssets: Boolean,
    publishableKey: String,
    paymentReturnUrl: String,
    auth: Object,
    paymentTierIds: Object,
    hasIntegration: Boolean,
    hasPayment: Boolean,
    companyRegNo: String,
    companyName: String,
    companyEmail: String,
    discountPercentage: Number,
    intensityFigures: Array,
  },
  data() {
    let selectedOnboardingStep = 1;

    // go to step 5 if installation has been installed and account mapping is not done
    if (this.$props.integrationInstalled) selectedOnboardingStep = 6;
    else if (this.$props.integrationError) selectedOnboardingStep = 3;

    return {
      emissionsPerMonthCurrentYear: [],
      emissionsPerMonthLastYear: [],
      assetCardData: {},
      co2MethodsByMonths: [],
      paymentDialog: false,
      onboardingDialog:
        this.$props.showOnboardingDialog ||
        !!this.$props.integrationError ||
        false,
      manualFileUploadModal: false,
      onboardingWarning: false,
      apiFieldsDialog: false,
      integrationDialog: false, //this.$props.integrationInstalled, // Disable interation dialog until accounts have been mapped
      selectedOnboardingStep,
      top20ListMode: "purchase",
      dateRange: {
        from: null,
        to: null,
      },
      scopes: null,
      highestCarbonAssets: [],
      highestCarbonSuppliers: [],
      highestCarbonOrganizations: [],
      emissionsByGhgCategories: [],
      emissionsByCo2Categories: [],
      highestEmittingAssetsOrganizationGroup: [],
      connectedToSystem: this.hasIntegration,
      showSkipMappingModal: false,
    };
  },
  methods: {
    formatPercentage(value, total) {
      const percent = (value / total) * 100;

      if (percent === 0) {
        return "0%";
      }

      if (percent < 1) {
        return "< 1%";
      }

      return Math.round(percent) + "%";
    },
    percentageValue(value, total) {
      const percent = (value / total) * 100;

      if (percent === 0) {
        return 0;
      }

      if (percent < 1) {
        return 1;
      }

      return percent;
    },
    handleSkipMapping() {
      this.showSkipMappingModal = true;
    },
    handleShowWarning() {
      this.onboardingDialog = false;
      this.onboardingWarning = true;
    },
    handleShowApiFields() {
      this.onboardingDialog = false;
      this.apiFieldsDialog = true;
    },
    handleShowIntegration() {
      this.onboardingDialog = false;
      this.integrationDialog = true;
    },
    handleContinueOnboarding() {
      this.onboardingWarning = false;
      this.apiFieldsDialog = false;
      this.onboardingDialog = true;
    },
    handleCloseAllPopups() {
      this.onboardingWarning = false;
      this.apiFieldsDialog = false;
      this.onboardingDialog = false;

      if (this.hasPayment === false) {
        this.paymentDialog = true;
      } else {
        this.paymentDialog = false;
      }
      this.stopShowingOnboarding();
    },
    stopShowingOnboarding() {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("showOnboardingDialog", "false");
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}?${searchParams}`
      );
    },
    handleSubmitApiFields(apiFields) {
      this.$refs.onboardingModalComponent.updateForm(
        "apiUser",
        apiFields.apiUser
      );
      this.$refs.onboardingModalComponent.updateForm(
        "authId",
        apiFields.authId
      );
      this.$refs.onboardingModalComponent.updateForm(
        "apiKey",
        apiFields.apiKey
      );
    },
    handleFinishIntegration() {
      this.onboardingDialog = true;
      this.$refs.onboardingModalComponent.finishOnboarding();
    },
    getFormattedEmissionAmountForValue(value) {
      const emissions = this.formatEmissions(value);
      return emissions.value + " " + emissions.unit;
    },
    async getInfoGraphData(dateRange) {
      // Amount change card data
      fetch(
        this.route("api.dashboard.infographics.assets.card-data", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          dashboard: "true",
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.assetCardData = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      // Co2 emissions overview
      fetch(
        this.route("api.dashboard.infographics.emissions.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsPerMonthCurrentYear = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      // Create compare seriest with same dates but within last year
      fetch(
        this.route("api.dashboard.infographics.emissions.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          yearOffset: -1,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsPerMonthLastYear = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.co2Methods.monthly", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
          yearOffset: -1,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.co2MethodsByMonths = data.map((method) => ({
            ...method,
            // transform/translate method name
            name: this.$t(
              `components.cards.emissionsByMethod.labels.${method.name}`
            ),
          }));
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.scopes.grouped", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.scopes = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route(
          "api.dashboard.infographics.lists.highestEmissionsSuppliers",
          {
            fromDate: dateRange.from,
            toDate: dateRange.to,
          }
        )
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.highestCarbonSuppliers = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route(
          "api.dashboard.infographics.lists.highestEmissionsPurchases",
          {
            fromDate: dateRange.from,
            toDate: dateRange.to,
          }
        )
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.highestCarbonAssets = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.lists.ghgCategoriesEmissions", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsByGhgCategories = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      fetch(
        this.route("api.dashboard.infographics.lists.emissionsByCategories", {
          fromDate: dateRange.from,
          toDate: dateRange.to,
        })
      )
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }
          return response.json();
        })
        .then((data) => {
          this.emissionsByCo2Categories = data;
        })
        .catch((error) => {
          console.warn("Could not load data", error);
        });

      if (this.isOrganizationGroup) {
        fetch(
          this.route(
            "api.dashboard.infographics.lists.organizations.emissions.highest",
            {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }
          ),
          {
            method: "POST",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            this.highestCarbonOrganizations = data;
          })
          .catch((error) => {
            console.warn("Could not load data", error);
          });

        fetch(
          this.route(
            "api.dashboard.infographics.lists.highestEmissionsOrganizationsGroupEmissions",
            {
              fromDate: dateRange.from,
              toDate: dateRange.to,
            }
          )
        )
          .then((res) => res.json())
          .then((data) => {
            this.highestEmittingAssetsOrganizationGroup = data;
          })
          .catch((error) => {
            console.warn("Could not load data", error);
          });
      }
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    barChartCalculateScale(value) {
      // Set height to 0 if value is 0
      if (value === 0 || value === null) {
        return 0;
      }

      // If value is really small but still above 0 return 0.1
      //just to make sure there's something there for the cursor to hover.
      else if (value <= 0.1) {
        return 0.1;
      }

      // Else return value
      return value;
    },
    changeToManualFileUploadFlow() {
      this.onboardingDialog = false;
      this.manualFileUploadModal = true;
    },
  },
  mounted() {
    const searchParams = new URLSearchParams(window.location.search);
    const showOnboardingDialog = searchParams.get("showOnboardingDialog");
    this.onboardingDialog = showOnboardingDialog === "true";
    this.syncDateRange((dateRange) => {
      // data needs to be cleared to rerender the graphs
      this.scopes = null;
      this.emissionsPerMonthCurrentYear = [];
      this.emissionsPerMonthLastYear = [];
      this.co2MethodsByMonths = [];

      // Fetch data in date range
      this.dateRange = dateRange;

      if (!this.showOverlay) {
        this.getInfoGraphData(dateRange);
      } else {
        // populate dummy data
        this.dateRange = dashboardDummyData.dateRange;
        this.assetCardData = dashboardDummyData.assetCardData;
        this.emissionsPerMonthCurrentYear =
          dashboardDummyData.emissionsPerMonthCurrentYear;
        this.emissionsPerMonthLastYear =
          dashboardDummyData.emissionsPerMonthLastYear;
        this.co2MethodsByMonths = dashboardDummyData.co2MethodsByMonths;
        this.scopes = dashboardDummyData.scopes;
        this.highestCarbonSuppliers = dashboardDummyData.highestCarbonSuppliers;
        this.highestCarbonAssets = dashboardDummyData.highestCarbonAssets;
      }
    });

    if (this.integrationInstalled || this.integrationError) {
      this.onboardingDialog = true;
    }
  },
  computed: {
    settings() {
      return this.$inertia.page.props.auth.settings;
    },
    showOverlay() {
      return this.connectedToSystem === false && this.hasAssets === false;
    },
    openPresentation() {
      return this.route("dashboard.presentation");
    },
    getAssetCountBars() {
      const approvedCount = this.assetCardData.numberOfAssetsApproved?.value;
      const definedCount = this.assetCardData.numberOfAssetsDefined?.value;
      const undefinedCount = this.assetCardData.numberOfAssetsUndefined?.value;

      const maxCount = Math.max(
        approvedCount,
        definedCount,
        undefinedCount,
        1 // Avoid dividing by zero
      );

      return [
        {
          label: this.$t("components.cards.titles.defined"),
          renderedValue: definedCount,
          fractionValue: this.barChartCalculateScale(definedCount / maxCount),
          color: "#898989",
          rawValue: definedCount,
        },
        {
          label: this.$t("components.cards.titles.undefined"),
          renderedValue: undefinedCount,
          fractionValue: this.barChartCalculateScale(undefinedCount / maxCount),
          color: "#F25D3B",
          rawValue: undefinedCount,
        },
        {
          label: this.$t("components.cards.titles.verified"),
          renderedValue: approvedCount,
          fractionValue: this.barChartCalculateScale(approvedCount / maxCount),
          color: "#266663",
          rawValue: approvedCount,
        },
      ];
    },
    getScopeCountBars() {
      if (this.scopes !== null) {
        const scope1 = this.assetCardData.scope1Emissions;
        const scope2 = this.assetCardData.scope2Emissions;
        const scope3 = this.assetCardData.scope3Emissions;

        let maxCount = Math.max(
          scope1?.value ?? 0,
          scope2?.value ?? 0,
          scope3?.value ?? 0,
          1 // Avoid dividing by zero
        );

        return [
          {
            label: "Scope 1",
            renderedValue:
              this.formatCurrency(scope1?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope1?.value / maxCount
            ),
            color: "#266663",
            rawValue: scope1?.value,
          },
          {
            label: "Scope 2",
            renderedValue:
              this.formatCurrency(scope2?.value ?? 0) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope2?.value / maxCount
            ),
            color: "#6493BE",
            rawValue: scope2?.value,
          },
          {
            label: "Scope 3",
            renderedValue:
              this.formatCurrency(scope3?.value) +
              " " +
              this.$t("common.units.kgCO2e"),
            fractionValue: this.barChartCalculateScale(
              scope3?.value / maxCount
            ),
            color: "#E35E3E",
            rawValue: scope3?.value,
          },
        ];
      }

      return [];
    },
    defaultCurrencyId() {
      return this.settings.defaultCurrencyId;
    },
    defaultCurrencyCode() {
      const currency = this.currencies.find(
        (x) => x.id === this.defaultCurrencyId
      );

      return currency?.currencyCode ?? null;
    },
    getScopes() {
      if (this.scopes === null) {
        return [];
      }

      let scopes = [];

      let scopeKeys = [_scope1, _scope2, _scope3, "other"];

      for (let key of scopeKeys) {
        let scope = this.scopes[key];

        if (scope === undefined || scope === null) scope = 0;

        scopes.push(scope);
      }

      return scopes;
    },
    mappedAssets() {
      let mappedAssets = [];
      this.highestCarbonAssets.forEach((asset) => {
        let mappedAsset = {
          ...asset,
        };

        mappedAsset.carbonFootprint = `0 ${this.$t("common.units.kgCO2e")}`;

        asset.assetAttributeLinks.forEach((assetAttributeLink) => {
          mappedAsset[assetAttributeLink.attributeId] = assetAttributeLink;

          if (
            assetAttributeLink.attributeId ===
            "041a251a-2138-4e14-bdfd-876194f4e04f"
          ) {
            mappedAsset.carbonFootprint = `${this.getFormattedEmissionAmountForValue(
              assetAttributeLink.decimalValue
            )}`;
          }
        });

        mappedAssets.push(mappedAsset);
      });

      return [...mappedAssets];
    },
    mappedHighestEmittingAssetsOrganizationGroup() {
      let mappedhighestEmittingAssetsOrganizationGroup = [];

      mappedhighestEmittingAssetsOrganizationGroup =
        this.highestEmittingAssetsOrganizationGroup.map((asset) => {
          return {
            name: asset.name,
            organization: asset.organization,
            emissions: this.getFormattedEmissionAmountForValue(asset.emissions),
            category: asset.category,
            rawValue: asset.emissions,
          };
        });

      mappedhighestEmittingAssetsOrganizationGroup.sort((a, b) =>
        a.rawValue < b.rawValue ? 1 : -1
      );

      return [...mappedhighestEmittingAssetsOrganizationGroup];
    },
    mappedSuppliers() {
      let mappedSuppliers = [];

      mappedSuppliers = this.highestCarbonSuppliers;

      mappedSuppliers = mappedSuppliers.map((supplier) => {
        return {
          rawValue: supplier.emissions,
          totalEmissionsRawValue: supplier.totalEmissions,
          emissions: this.getFormattedEmissionAmountForValue(
            supplier.emissions
          ),
          name: supplier.name,
          numberOfPurchases: supplier.numberOfPurchases,
        };
      });
      mappedSuppliers.sort((a, b) => (a.rawValue < b.rawValue ? 1 : -1));

      return [...mappedSuppliers];
    },
    mappedOrganizations() {
      let mappedOrganizations = [];

      this.highestCarbonOrganizations.forEach((organization) => {
        const emissions = organization.emissions;

        const formattedValue =
          this.getFormattedEmissionAmountForValue(emissions);

        mappedOrganizations.push({
          name: organization.name,
          emissions: `${formattedValue}`,
          rawValue: organization.emissions,
          totalEmissionsRawValue: organization.totalEmissions,
          numberOfPurchases: organization.count,
        });
      });

      return mappedOrganizations;
    },
    mappedGhgCategories() {
      let mappedCategories = [];
      Object.keys(this.emissionsByGhgCategories).forEach((key) => {
        const category = this.emissionsByGhgCategories[key];
        mappedCategories.push({
          category: this.$t(
            "components.cards.ghgCategories.categories." + category.name
          ),
          emissions: this.getFormattedEmissionAmountForValue(
            category.emissions
          ),
          rawValue: category.emissions,
          totalEmissionsRawValue: category.totalEmissions,
        });
      });

      mappedCategories.sort((a, b) => (a.rawValue <= b.rawValue ? 1 : -1));

      return [...mappedCategories];
    },
    mappedEmissionCategories() {
      return this.emissionsByCo2Categories.map((category) => {
        return {
          category: category.category,
          emissions: this.getFormattedEmissionAmountForValue(
            category.emissions
          ),
          rawValue: category.emissions,
          totalEmissionsRawValue: category.totalEmissions,
        };
      });
    },
    months1YearToCurrent() {
      const monthCountInSelection = this.monthDiff(
        new Date(this.dateRange.from),
        new Date(this.dateRange.to)
      );

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const currentMonthIndex = new Date(this.dateRange.from).getMonth();

      var rMonths = [];
      for (var i = 0; i <= monthCountInSelection; i++) {
        var month = months[(currentMonthIndex + i) % 12];
        rMonths.push(month);
      }

      return rMonths;
    },
    co2CategoryLabels() {
      return [
        this.$t("components.cards.co2Categories.scope1"),
        this.$t("components.cards.co2Categories.scope2"),
        this.$t("components.cards.co2Categories.scope3"),
        this.$t("components.cards.co2Categories.unassigned"),
      ];
    },
    emissionsBySupplierHeaders() {
      return [
        {
          text: this.$t("components.cards.top20Suppliers.fields.supplierName"),
          align: "start",
          sortable: false,
          value: "name",
          width: "100%",
        },
        {
          text: this.$t(
            "components.cards.top20Suppliers.fields.numberOfPurchases"
          ),
          value: "numberOfPurchases",
        },
        {
          text: this.$t("components.cards.top20Suppliers.fields.emissions"),
          value: "emissions",
        },
      ];
    },
    emissionsBypurchaseHeader() {
      return [
        {
          text: this.$t("components.cards.top20Purchases.fields.productName"),
          align: "start",
          sortable: false,
          value: "649f345f-bb47-47b5-af15-6ef294e72afd.stringValue",
          width: "100%",
        },
        {
          text: this.$t(
            "components.cards.top20Purchases.fields.carbonFootprint"
          ),
          value: "carbonFootprint",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.category"),
          value: "5ac7671d-fb9d-4441-8203-a09763fbcff8.searchString",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.price"),
          value: "8192dfef-ef4b-4ed1-9c9e-26ed84ef8de2.searchString",
        },
      ];
    },
    highestEmittingAssetsOrganizationGroupHeader() {
      return [
        {
          text: this.$t("components.cards.top20Purchases.fields.productName"),
          align: "start",
          sortable: false,
          value: "name",
          width: "100%",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.organization"),
          value: "organization",
        },
        {
          text: this.$t("components.cards.top20Purchases.fields.category"),
          value: "category",
        },
        {
          text: this.$t(
            "components.cards.top20Purchases.fields.carbonFootprint"
          ),
          value: "emissions",
        },
      ];
    },
    emissionsByOrganizationsHeaders() {
      return [
        {
          text: this.$t(
            "components.cards.top20Organizations.fields.organizationName"
          ),
          align: "start",
          sortable: false,
          value: "name",
          width: "100%",
        },
        {
          text: this.$t(
            "components.cards.top20Suppliers.fields.numberOfPurchases"
          ),
          value: "numberOfPurchases",
        },
        {
          text: this.$t("components.cards.top20Organizations.fields.emissions"),
          value: "emissions",
        },
      ];
    },
    emissionsByGhgCategoriesHeader() {
      return [
        {
          text: this.$t("components.cards.ghgCategories.fields.category"),
          align: "start",
          sortable: false,
          value: "category",
          width: "100%",
        },
        {
          text: this.$t("components.cards.ghgCategories.fields.emissions"),
          value: "emissions",
        },
      ];
    },
    emissionsByEmissionsCategoriesHeader() {
      return [
        {
          text: this.$t("components.cards.top20Co2Categories.fields.category"),
          align: "start",
          sortable: false,
          value: "category",
          width: "100%",
        },
        {
          text: this.$t("components.cards.top20Co2Categories.fields.emissions"),
          value: "emissions",
        },
      ];
    },
    formattedCo2MethodsByMonths() {
      return this.co2MethodsByMonths.map((data) => ({
        ...data,
        name: this.$t(`components.cards.emissionsByMethod.labels.${data.name}`)
          ? this.$t(`components.cards.emissionsByMethod.labels.${data.name}`)
          : data.name,
      }));
    },
    numberOfAssets() {
      let number = this.assetCardData?.numberOfAssetsCard?.value;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
    treesToOffset() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value;

      if (!totalCo2) return "0";

      const trees = Math.ceil(totalCo2 / 25)?.toString();

      return integerNumberFormatter.format(trees);
    },
    totalCo2Used() {
      let totalCo2 = this.assetCardData?.totalCo2UsedCard?.value ?? 0;
      return this.formatEmissions(totalCo2);
    },
    co2Ytd() {
      let co2Ytd = this.assetCardData?.co2CurrentYearCard?.value ?? 0;
      return this.formatEmissions(co2Ytd);
    },
    emissionsPerMonthOver1000kg() {
      const currentYearLength = this.emissionsPerMonthCurrentYear?.length ?? 0;
      const lastYearLength = this.emissionsPerMonthLastYear?.length ?? 0;

      if (!currentYearLength || !lastYearLength) return false;

      const arrayMax = (previous, current) =>
        previous > current ? previous : current;

      const currentYearMax =
        this.emissionsPerMonthCurrentYear?.reduce(arrayMax) ?? 0;
      const lastYearMax = this.emissionsPerMonthLastYear?.reduce(arrayMax) ?? 0;

      return currentYearMax >= 1000 || lastYearMax >= 1000;
    },
    adjustedEmissionsPerMonthCurrentYear() {
      const currentDate = new Date();

      // Only include months that have already passed (only when actually using current year)
      let currentMonth = 12; // Include all months if not in current year
      if (this.dateRange["to"].split("-")[0] === currentDate.getFullYear()) {
        currentMonth = new Date().getMonth();
      }

      const _emissionsPerMonthCurrentYear =
        this.emissionsPerMonthCurrentYear?.slice(0, currentMonth);

      if (!this.emissionsPerMonthOver1000kg)
        return _emissionsPerMonthCurrentYear;

      return _emissionsPerMonthCurrentYear?.map((x) => x / 1000) ?? [];
    },
    adjustedEmissionsPerMonthLastYear() {
      if (!this.emissionsPerMonthOver1000kg)
        return this.emissionsPerMonthLastYear;

      return this.emissionsPerMonthLastYear?.map((x) => x / 1000) ?? [];
    },
    isOrganizationGroup() {
      return this.auth.organizationGroups.some(
        (item) => item.id === this.auth.organizationId
      );
    },
    totalSpentAmount() {
      // get amount from assetCardData
      let total = this.assetCardData?.totalAmountSpent?.value ?? 0;

      return currencyFormatter.format(total);
    },
    totalSpentAmountUnit() {
      return this.assetCardData?.totalAmountSpent?.unit;
    },
    numberOfFiles() {
      let number = this.assetCardData?.numberOfFiles;

      if (!number) return "0";

      return integerNumberFormatter.format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-table-card:deep() {
  tr td,
  tr th {
    padding-left: 0 !important;
  }
}

.custom-skeleton {
  background: rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;
}
.custom-skeleton::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  animation: skeleton-loading 1.5s infinite;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  z-index: 1;
}

@keyframes skeleton-loading {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.data-table:deep(th) {
  color: #b4b4b4 !important;

  text-wrap: nowrap;
}

.data-table:deep(tr) {
  &:hover {
    background: initial !important;
  }
}

.table-select-btn {
  min-width: unset !important;
}

.connect-blur {
  filter: blur(1.8px);
}

//
.connect-color-overlay {
  > :deep(*) {
    background: rgba(255, 255, 255, 0.3882352941);
    filter: opacity(0.4) !important;
  }
}

.connect-overlay {
  position: absolute;
  inset: 0;
  top: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .connect-content {
    position: sticky;
    top: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px;
  }
}

.download-icon {
  height: 62px;
  width: 62px;
  background-color: #fdece7;
  border-radius: 100%;
}

.svg-button {
  padding: 0;
  border: none;
  background-color: transparent;
  width: 36px;
  height: 36px;
  min-width: unset !important;
  min-height: unset !important;
}

:deep(td) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
</style>
