<template>
  <div class="px-5">
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{ 'red-border': selectedIntegration === integrations.economic }"
        @click="selectIntegration(integrations.economic)"
        style="height: 90px"
      >
        <EConomicLogo />
      </div>
    </v-row>
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{ 'red-border': selectedIntegration === integrations.uniconta }"
        @click="selectIntegration(integrations.uniconta)"
        style="height: 90px"
      >
        <UnicontaLogo />
      </div>
    </v-row>
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{
          'red-border': selectedIntegration === integrations.businessCentral,
        }"
        @click="selectIntegration(integrations.businessCentral)"
        style="height: 90px"
      >
        <Dynamic365Logo />
      </div>
    </v-row>
<!--     <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{
          'red-border':
            selectedIntegration === integrations.businessCentralOnPremise,
        }"
        @click="selectIntegration(integrations.businessCentralOnPremise)"
        style="height: 90px"
      >
        <img
          :src="
            require('../../../assets/integration-logos/dynamic365-on-premise.webp')
          "
        />
      </div>
    </v-row>
    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{
          'red-border': selectedIntegration === integrations.ekomplet,
        }"
        @click="selectIntegration(integrations.ekomplet)"
        style="height: 90px"
      >
        <div
          class="justify-center d-flex align-center pointer"
          style="background: #364550; width: 286px; height: 90px"
        >
          <img
            style="width: 156px; height: auto"
            :src="require('../../../assets/integration-logos/e-komplet.png')"
          />
        </div>
      </div>
    </v-row> -->

    <v-row align="center">
      <div
        class="col-12 border mb-3 justify-center d-flex align-center pointer"
        :class="{
          'red-border': selectedIntegration === integrations.manualFileUpload,
        }"
        @click="selectIntegration(integrations.manualFileUpload)"
        style="height: 90px"
      >
        <v-row align="center">
          <v-col class="d-flex align-center justify-center flex-col">
            <v-icon x-large color="black">mdi-file-document-outline</v-icon>
            <span class="text-h6 ml-4 d-flex align-center">
              {{ $t("components.onboarding.stepThree.manualFileUpload") }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-row v-if="showContinue" align="center">
      <div
        class="col-12 border justify-center d-flex align-center flex-column pointer"
        :class="{ 'red-border': selectedIntegration === integrations.other }"
        @click="selectIntegration(integrations.other)"
        :style="{
          height: selectedIntegration === integrations.other ? '90px' : '90px',
        }"
      >
        <div class="py-2">
          <span
            class="pointer"
            :style="{
              color:
                selectedIntegration === integrations.other
                  ? '#F25D3B'
                  : 'inherit',
            }"
            disabled
          >
            {{ $t("components.onboarding.stepThree.systemNotHere") }}
          </span>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import EConomicLogo from "../../../assets/svg/visma-e-conomic.svg";
import UnicontaLogo from "../../../assets/svg/uniconta.svg";
import Dynamic365Logo from "../../../assets/svg/dynamic365.svg";

import {
  economic,
  uniconta,
  businessCentral,
  businessCentralOnPremise,
  ekomplet,
  manualFileUpload,
  other,
} from "@/util/integrations";

export default {
  components: {
    EConomicLogo: EConomicLogo,
    UnicontaLogo: UnicontaLogo,
    Dynamic365Logo: Dynamic365Logo,
  },
  props: {
    showContinue: Boolean,
  },
  data() {
    return {
      selectedIntegration: null,
      otherIntegration: "",
      integrations: {
        economic: economic,
        uniconta: uniconta,
        ekomplet: ekomplet,
        businessCentral: businessCentral,
        businessCentralOnPremise: businessCentralOnPremise,
        manualFileUpload: manualFileUpload,
        other: other,
      },
    };
  },
  methods: {
    selectIntegration(item) {
      if (this.selectedIntegration === item) {
        this.selectedIntegration = null;
      } else {
        this.selectedIntegration = item;
      }

      this.$emit("update:selected-integration", this.selectedIntegration);
      this.$emit("updateForm", "financialSystem", this.selectedIntegration);
    },
    emitOtherIntegration() {
      this.$emit("otherIntegration", this.otherIntegration);
    },
  },
  mounted() {
    this.selectedIntegration = null;
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 2px solid #f7f7f7;
}

.red-border {
  border-color: #f25d3b;
}

input:focus {
  outline: none !important;
  box-shadow: 0 0 3px black;
}
.pointer {
  cursor: pointer;
}
</style>
