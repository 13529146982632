import { render, staticRenderFns } from "./FormB3.vue?vue&type=template&id=4b1b4cb9&scoped=true"
import script from "./FormB3.vue?vue&type=script&lang=js"
export * from "./FormB3.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b1b4cb9",
  null
  
)

export default component.exports