var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"py-1 navigation-list",class:_vm.mini ? 'mini' : 'expanded',attrs:{"flat":""}},[_c('v-list-item-group',{model:{value:(_vm.selectedMenu),callback:function ($$v) {_vm.selectedMenu=$$v},expression:"selectedMenu"}},[_vm._l((_vm.filteredItems),function(item,index){return [(item.type && item.type === 'heading')?_c('div',{key:item.type && item.type === 'heading'
            ? 'heading-' + index
            : item.route,staticClass:"navigation-heading px-4",style:(_vm.mini
            ? 'font-size: 10px'
            : 'font-size: 14px; padding-left: 16px; margin-left: 15px;')},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('v-list-group',{key:item.route,ref:'list-groups',refInFor:true,staticClass:"v-list-item--dense",attrs:{"append-icon":null,"href":_vm.handleMainRoute(item),"value":_vm.isOnRoute(item)},on:{"click":function($event){return _vm.visit(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"d-flex flex-row align-center",class:item.subRoutes || item.displayAsTopLevel
                ? 'top-level-group'
                : null,attrs:{"to":item.route}},[(item.iconComponent)?_c('div',{staticClass:"menu-item-icon",class:_vm.isOnRoute(item) ? 'highlighted-icon' : '',staticStyle:{"margin":"0px 15px"}},[_c(item.iconComponent,{tag:"component"})],1):_vm._e(),_c('div',{staticClass:"block py-5 flex-grow-1",class:_vm.isOnRoute(item) ? 'is-on-route' : ''},[_vm._v(" "+_vm._s(item.title)+" ")]),(_vm.shouldNotify(item))?_c('span',{staticClass:"red-dot",class:_vm.mini ? 'red-dot-mini' : null}):_vm._e(),(!_vm.mini && item.subRoutes)?_c('v-icon',{attrs:{"color":_vm.isOnRoute(item) || true ? '#F25D3B' : '#F25D3B80'}},[_vm._v("mdi-chevron-down")]):_vm._e()],1)]},proxy:true}],null,true)},[(item.subRoutes)?_c('v-divider',{staticClass:"navigation-divider"}):_vm._e(),_vm._l((item.subRoutes),function(sub){return _c('v-list-item',{directives:[{name:"inertia",rawName:"v-inertia"}],key:sub.route,attrs:{"link":"","dense":"","active-class":"","href":_vm.handleSubRoute(sub)}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"py-2 d-flex",class:_vm.isOnRoute(sub) ? 'white--text' : ''},[(sub.iconComponent)?_c('div',{staticClass:"menu-item-icon",class:_vm.isOnRoute(sub) ? 'highlighted-icon' : '',staticStyle:{"margin":"0px 15px"}},[_c(sub.iconComponent,{tag:"component"})],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(sub.title)+" ")]),(_vm.shouldNotify(sub))?_c('span',{staticClass:"red-dot"}):_vm._e()])],1)],1)}),(item.subRoutes)?_c('v-divider',{staticClass:"navigation-divider"}):_vm._e()],2)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }