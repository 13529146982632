<template>
  <tr>
    <td class="text-start fixed">
      <v-simple-checkbox
        color="#F25D3B"
        :ripple="false"
        :value="isSelected"
        @input="$emit('toggle-selected')"
      ></v-simple-checkbox>
    </td>
    <td
      v-for="field in entryFields"
      :key="field"
      class="text-start"
      :class="getColumnStatusClass(field)"
    >
      <div class="fill-height d-flex align-center">
        <v-autocomplete
          v-if="isEditMode && isUnitTypeField(field)"
          :items="unitTypes"
          :value="entry[field]"
          dense
          flat
          hide-details
          clearable
          @change="$emit('edit', field, $event)"
          @keydown="saveOnEnter($event)"
        ></v-autocomplete>
        <v-text-field
          v-else-if="isEditMode"
          :value="entry[field]"
          dense
          flat
          hide-details
          clearable
          @input="$emit('edit', field, $event)"
          @keydown="saveOnEnter($event)"
        ></v-text-field>
        <span
          v-else
          class="text-truncate text-nowrap"
          style="max-width: 300px"
          >{{ entry[field] }}</span
        >
        <template v-if="getFieldError(field) && !isEditMode">
          <div class="flex-grow-1"></div>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <div>
              {{ getFormattedFieldError(field) }}
            </div>
          </v-tooltip>
        </template>
        <template v-if="getFieldWarning(field) && !isEditMode">
          <div class="flex-grow-1"></div>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <div>
              {{ getFormattedFieldWarning(field) }}
            </div>
          </v-tooltip>
        </template>
      </div>
    </td>
    <td class="text-start fixed">
      <v-icon size="20" class="pa-1 mr-1" @click="$emit('toggle-edit-mode')">
        {{ isEditMode ? "mdi-check" : "mdi-pencil" }}
      </v-icon>
      <v-icon size="20" class="pa-1" @click="$emit('delete')">
        mdi-delete
      </v-icon>
    </td>
  </tr>
</template>
<script>
import { importFieldName } from "../../util/accountingEntryData";

export default {
  props: {
    entry: Object,
    isSelected: Boolean,
    isEditMode: Boolean,
    fieldMappings: Object,
    columnErrorMessages: {
      type: Object,
      default: () => ({}),
    },
    columnWarningMessages: {
      type: Object,
      default: () => ({}),
    },
    unitTypes: Array,
  },
  computed: {
    entryFields() {
      return Object.keys(this.entry).filter((x) => x !== "index");
    },
  },
  methods: {
    isUnitTypeField(index) {
      return this.fieldMappings[index] === importFieldName.unitType;
    },
    saveOnEnter(event) {
      if (event.key !== "Enter") return;

      this.$emit("toggle-edit-mode");
    },
    getFieldError(field) {
      const columnErrorMessages = this.columnErrorMessages ?? {};

      return columnErrorMessages[field];
    },
    getFormattedFieldError(field) {
      const errorCode = this.getFieldError(field);

      if (!errorCode) return null;

      return this.$t(
        `components.integrations.manualFileUpload.stepThree.errors.${errorCode}`
      );
    },
    getFieldWarning(field) {
      const columnWarningMessages = this.columnWarningMessages ?? {};

      return columnWarningMessages[field];
    },
    getFormattedFieldWarning(field) {
      const errorCode = this.getFieldWarning(field);

      if (!errorCode) return null;

      return this.$t(
        `components.integrations.manualFileUpload.stepThree.warnings.${errorCode}`
      );
    },
    getColumnStatusClass(field) {
      if (this.isEditMode) return "";

      if (this.getFieldError(field)) return "red";

      if (this.getFieldWarning(field)) return "yellow";

      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.error {
  background: #f25d3b;
}
</style>
